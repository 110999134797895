/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiMemberDtoSendMailToExternalDto
 */
export interface WebApiMemberDtoSendMailToExternalDto {
    /**
     * 
     * @type {Array<Blob>}
     * @memberof WebApiMemberDtoSendMailToExternalDto
     */
    files?: Array<Blob> | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoSendMailToExternalDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoSendMailToExternalDto
     */
    message?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebApiMemberDtoSendMailToExternalDto
     */
    emails?: Array<string> | null;
}

/**
 * Check if a given object implements the WebApiMemberDtoSendMailToExternalDto interface.
 */
export function instanceOfWebApiMemberDtoSendMailToExternalDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoSendMailToExternalDtoFromJSON(json: any): WebApiMemberDtoSendMailToExternalDto {
    return WebApiMemberDtoSendMailToExternalDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoSendMailToExternalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoSendMailToExternalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'files': !exists(json, 'files') ? undefined : json['files'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
    };
}

export function WebApiMemberDtoSendMailToExternalDtoToJSON(value?: WebApiMemberDtoSendMailToExternalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'files': value.files,
        'subject': value.subject,
        'message': value.message,
        'emails': value.emails,
    };
}

