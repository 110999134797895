import { useEffect, useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Divider, Form, InputNumber, Radio, Row, Select, Space, Switch, Tag, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useNavigate, useParams } from "react-router-dom";
import { EventService } from '../../Services/EventService';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useUpdateEvent } from '../../Hooks/useUpdateEvent';
import moment from 'moment';
import 'moment-timezone'
import { EventEditState } from '../../Pages/Event/EventState';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { BookyInput, InputType } from '../../Components/Forms/BookyInput';
import { BookyCustomInput } from '../../Components/Forms/BookyCustomInput';
import { GooglePlaceAutoComplete } from '../../Components/AutoComplete/GooglePlaceAutocomplete';
import { BookyWeekCalendar } from '../../Components/Calendar/WeekCalendar';
import { BookyEventList } from '../../Components/Calendar/BookyEventList';
import { AuthProvider } from '../../Providers/authProvider';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import LeftMenuEventDetails from './LeftMenuEventDetails';
import dayjs, { Dayjs } from 'dayjs';

const MyEventEdit = () => {
  const [event, setEvent] = useState([] as any);
  const { events, step, changeStep, CreateEventItem, UpdateEvent, DeleteEvent, EditEvent, timeZone, setTimeZone, LoadTimeZones, timeZones, SetFullDayOnEvent, setMaxParticipants, maxParticipants, setAutomaticReminder, automaticReminder, setDeadline, deadline, displayType, setDisplayType, AddEvents, eventState, setEventState } = useUpdateEvent();
  const [form] = Form.useForm();
  const [show24hrsFormat, setShow24hrsFormat] = useState(false as boolean);
  const [startDate, setStartDate] = useState("" as string);
  const [user, setUser] = useState({} as AuthenticatedUser | null);

  const GetText = TextService.GetText;

  const navigation = useNavigate();
  let params = useParams();

  const FormCreateClick = async (formValues: any) => {
    const newlyCreatedEvent = await EditEvent(event.eventId, formValues);
    navigation("/" + getCurrentLanguage() + "/myevents/" + event.eventUniqueId, {
      state: {
        uniqueid: newlyCreatedEvent.uniqueId,
      }
    });
  }

  const OnStepChange = (event: any) => {
    changeStep(event);
  }

  const OnMaxParticipantsChange = (event: any) => {
    setMaxParticipants(event);
  }

  const onDisplayTypeChange = (event: any) => {
    setDisplayType(event.target.checked ? 1 : 0);
  }

  const onTimeZoneChange = (value: string) => {
    setTimeZone(value);
  };

  const onChange = (
    (date: Dayjs | string[] | null | undefined, dateString: string | string[]) => {
      setDeadline(dateString.toString());
    });

  const onDeleteEvent = (id: any) => {
    DeleteEvent(id);
  }

  const onNavigate = (date: any) => {
    setStartDate(moment(date).startOf('week').toISOString());
  }


  useEffect(() => {
    if (params.id != null) {

      AuthProvider().GetLoggedInUser().then(user => {
        setUser(user);
      });

      EventService.GetEventDetails(params.id).then(result => {
        form.setFieldsValue({
          name: result.eventName,
          eventDescription: result.eventDescription,
          eventLocation: result.eventLocation,
        });

        AddEvents(result.times);

        if (result.eventDeadline) {
          setDeadline(result.eventDeadline.toString());
        }


        if (result.automaticReminder) {
          setAutomaticReminder(true);
        }

        if (result.eventTimeZoneId) {
          LoadTimeZones(result.eventTimeZoneId);
          moment.tz.setDefault(result.eventTimeZoneId);
        }

        if (result.eventDisplayType) {
          setDisplayType(result.eventDisplayType);
        }


        if (result.eventParticipants) {
          setMaxParticipants(result.eventParticipants);
        }

        if (result.timeAnswers != null && result.timeAnswers.length > 0) {
          setEventState(EventEditState.HasBookings);
        }

        setEvent(result);

        if (getCurrentLanguage() === "se") {
          setShow24hrsFormat(true);
        }
      });
    }
  }, [])

  return <LayoutLeftMenu hasTitleTop={true} titleTop={event.eventName} fullpageMain={true} title={GetText("SendMail_SendMailTitle")} menu={<LeftMenuEventDetails selected={"editevent"} />}>
    <Col span={16}>
      <Col className='bookyBox' style={{ marginLeft: "20px" }}>
        <Form
          data-testid="create-form"
          name="basic"
          colon={false}
          form={form}
          labelCol={{
            span: 4,
          }}
          onFinish={FormCreateClick}
          wrapperCol={{
            span: 16,
          }}
          layout="horizontal"
          initialValues={{
            name: event.name,
            remember: true,
          }}
          autoComplete="off"
        >
          <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
            <Title style={{ margin: 0 }}>{GetText("MyEventsEdit_UpdateEvent")}</Title>
          </Space>
          <Divider />
          <BookyInput label={GetText("MyEventsEdit_Name_Label")} name="name" requiredMessage={GetText("MyEventsEdit_Name_Required")} placeholder={GetText("MyEventsEdit_Name_Placeholder")} />
          <BookyInput label={GetText("MyEventsEdit_Description_Label")} name="eventDescription" type={InputType.multiline} placeholder={GetText("MyEventsEdit_Description_Placeholder")} />

          <BookyCustomInput label={GetText("MyEventsEdit_Location_Label")} name="eventLocation" placeholder={GetText("MyEventsEdit_Location_Placeholder")}>
            <GooglePlaceAutoComplete defaultValue={event.eventLocation} onPlaceSelected={(place: any) => { form.setFieldValue("eventLocation", place) }} />
          </BookyCustomInput>

          <Form.Item
            label={GetText("MyEventsEdit_Deadline_Label")}
            name="eventDueDate"
            rules={[
              {
                required: false,
                message: GetText("MyEventsEdit_Deadline_LastResponse"),
              },
            ]}
          >
            {user?.hasPaidSubscription ?
              <DatePicker placeholder={GetText("MyEventsEdit_Deadline_Description")} format={"YYYY-MM-DD HH:mm"} showTime={{ format: 'HH:mm' }} value={deadline != "" ? dayjs(deadline) : null} style={{ justifyContent: 'left', width: '100%' }} onChange={onChange} /> :
              <span className='pro-link' style={{ justifyContent: 'left', display: 'flex' }}><Tag color="#1890ff">{GetText("Common_Premium")}</Tag><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a> {GetText("MyEventsEdit_PremiumDescription")}</span>
            }
            <span style={{ justifyContent: 'left', display: "flex", fontSize: "80%" }}>{GetText("MyEventsEdit_DeadlineDescriptionText")}</span>
          </Form.Item>

          <BookyCustomInput label={GetText("Create_AutomaticReminder")} helpText={GetText("Create_AutomaticReminder_Placeholder")}>
            {user?.hasPaidSubscription ?
              <Checkbox checked={automaticReminder} onChange={(e) => { setAutomaticReminder(e.target.checked) }}>{GetText("Create_AutomaticReminder_Send")}</Checkbox> :
              <span className='pro-link' style={{ justifyContent: 'left', display: 'flex' }}><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a> {GetText("Create_AutomaticReminder_Premium_ToSendAutomaticReminder")}</span>
            }
          </BookyCustomInput>

          {eventState === EventEditState.Found &&
            <Form.Item
              style={{ textAlign: "left" }}
              label={GetText("MyEventsEdit_Participants_Label")}
              name="eventMaxParticipants"
              rules={[
                {
                  required: false,
                  message: GetText("MyEventsEdit_Participants_Description"),
                },
              ]}
            >
              {user?.subscriptionLevel === SubscriptionLevel.Free ?
                <span>
                  <InputNumber min={1} value={maxParticipants} max={25} onChange={OnMaxParticipantsChange} />
                  <Paragraph style={{ fontSize: "80%" }}>{GetText("MyEventsEdit_RestrictAnswers")} <a href='/Pricing'>Upgrade to premium</a> {GetText("MyEventsEdit_UpgradeToPremiumToAllowMoreGuests")}</Paragraph>
                </span>
                :
                <span>
                  <InputNumber min={1} max={999} value={maxParticipants} onChange={OnMaxParticipantsChange} />
                  <Paragraph style={{ fontSize: "80%" }}>{GetText("MyEventsEdit_RestrictNumberOfAnswers")}</Paragraph>
                </span>
              }
            </Form.Item>
          }

          <Form.Item
            style={{ textAlign: "left" }}
            label=" "
            name="eventDisplayType"
            rules={[
              {
                required: false,
                message: 'Hide participant list',
              },
            ]}
          >
            <Checkbox checked={displayType === 1} onChange={onDisplayTypeChange}>{GetText("MyEventsEdit_HideParticipantList")} <span style={{ fontSize: "80%" }}>{GetText("MyEventsEdit_HideParticipantList_Description")}</span></Checkbox>
          </Form.Item>

          {eventState === EventEditState.HasBookings ?
            <Space style={{ width: '100%', justifyContent: 'left', marginBottom: "20px" }}>
              <Typography style={{ width: '100%', justifyContent: 'left', margin: 0 }}><b>{GetText("MyEventsEdit_HasGuestsLockedChanges")}</b></Typography>
            </Space>
            : ""}

          {eventState === EventEditState.Found &&
            <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
              <Title level={4} style={{ margin: 0 }}>{GetText("MyEventsEdit_AddTimes")}</Title>
              <Typography style={{ width: '100%', justifyContent: 'left', margin: 0 }}></Typography>
            </Space>
          }

          {eventState === EventEditState.Found &&
            <Form.Item
              label={GetText("MyEventsEdit_Timezone_Label")}
              name="timezone"
              rules={[
                {
                  required: false,
                  message: GetText("MyEventsEdit_LengthOfEvent"),
                },
              ]}
            >
              <span hidden>{step}</span>
              <Select
                showSearch
                placeholder={GetText("MyEventsEdit_SelectTimezone")}
                optionFilterProp="children"
                value={timeZone}
                onChange={onTimeZoneChange}
                filterOption={(input, option) =>
                  (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                }
                options={timeZones}
              />
            </Form.Item>
          }
          {eventState === EventEditState.Found && <BookyCustomInput label={GetText("MyEventsEdit_Duration")} name="eventDuration">
            <Radio.Group value={step} onChange={(e) => { OnStepChange(e.target.value) }} defaultValue="a" buttonStyle="solid">
              <Radio.Button value={15}>15 min</Radio.Button>
              <Radio.Button value={30}>30 min</Radio.Button>
              <Radio.Button value={60}>60 min</Radio.Button>
              <Radio.Button value={80}>{GetText("MyEventsEdit_CustomTime")}</Radio.Button>
              <Radio.Button value={-2}>{GetText("MyEventsEdit_AllDay")}</Radio.Button>
            </Radio.Group>
            {(step != 15 && step != 30 && step != 60 && step != -2) ? <div style={{ width: "100%" }}><InputNumber style={{ justifyContent: 'left' }} min={5} max={600} value={step} onChange={OnStepChange} /></div> : ""}
          </BookyCustomInput>}


          {/* {eventState === EventEditState.Found && <BookyCustomInput label={GetText("MyEventsEdit_CalenderFormat")} name="eventDuration">
            <Switch checked={show24hrsFormat} onChange={(e) => { setShow24hrsFormat(!show24hrsFormat) }} checkedChildren="24" unCheckedChildren="AM/PM" defaultChecked />
          </BookyCustomInput>} */}

          {eventState === EventEditState.Found &&
            <Row>
              <Col span={20}>
                <BookyWeekCalendar startDate={startDate} onNavigate={onNavigate} DeleteEvent={DeleteEvent} events={events} CreateEventItem={CreateEventItem} UpdateEvent={UpdateEvent} show24hrsFormat={show24hrsFormat} />
              </Col>
              <Col span={4}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {GetText("Create_DateTimeTitle")}{events.length})
                </div>
                <div>
                  <BookyEventList events={events} onDeleteEvent={onDeleteEvent} SetFullDayOnEvent={SetFullDayOnEvent} show24hrsFormat={show24hrsFormat} />
                </div>
              </Col>
            </Row>
          }
          <br></br>

          <Form.Item
            label=""
            name="eventLocation"
            rules={[
              {
                required: false,
                message: GetText("MyEventsEdit_LocationOfEvent"),
              },
            ]}
          >
            <Button data-testid="submit-button" style={{ float: 'left' }} size="middle" type="primary" htmlType="submit">
              {GetText("MyEventsEdit_UpdateEventButton")}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Col>
  </LayoutLeftMenu >
};

export default MyEventEdit;