

import { useState } from 'react';
import { Alert, Modal, Upload } from 'antd';
import { BookyButton } from '../../Buttons/BookyButton';
import { WebApiMemberDtoAddMemberObjectDto, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../../Repository/eventbookyapi';
import { SubscriptionLevel } from '../../../Types/SubscriptionLevel';
import { TextService } from '../../../Services/TextService';
import { FileOutlined } from '@ant-design/icons';
import { MemberService } from '../../../Services/MemberService';
import { UploadMemberPreviewData } from './UploadMemberPreviewTable';
import { isEmail } from 'react-multi-email';
import { MemberStatusType } from '../../../Types/MemberStatusType';
import { mapToMemberStatus } from '../../../Mappers/mapGroupToListItem';
const xlsx = require('xlsx');

const enum UploadErrorType { Unspecified, EmptyFile, FreeTooManyUsers, PremiumTooManyUsers }

export const UploadMemberButton = (props: any) => {
    const [members, setMembers] = useState([] as WebApiMemberDtoAddMemberObjectDto[]);
    const [validMembers, setValidMembers] = useState([] as WebApiMemberDtoAddMemberObjectDto[]);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(UploadErrorType.Unspecified as UploadErrorType);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const GetText = TextService.GetText;


    const CloseModalAndClearState = () => {
        setOpen(false);
        setError(UploadErrorType.Unspecified);
        setIsLoading(false);
        setSuccessText(GetText("Common_UploadComplete"));
    }

    const CloseModalAndShowNothing = () => {
        setOpen(false);
        setError(UploadErrorType.Unspecified);
        setIsLoading(false);
    }

    const showError = (error: UploadErrorType) => {
        let text = "";
        switch (error) {
            case UploadErrorType.EmptyFile:
                text = GetText("UploadMembers_WrongFormat"); break;
            case UploadErrorType.FreeTooManyUsers:
                text = GetText("UploadMembers_Found") + members.length + GetText("UploadMembers_ErrorMoreThenMaxMembersInFree1") + GetText("UploadMembers_ErrorMoreThenMaxMembersInFree2");
                break;
            case UploadErrorType.PremiumTooManyUsers:
                text = GetText("UploadMembers_Found") + members.length + GetText("UploadMembers_ErrorMoreThenMaxMembersInPremium");
                break;
        }

        setErrorText(text);
    }

    const trimFromCsv = (value: string) => {
        return value ? value.trim() : ""
    }

    const onFileUpload = (event: any, reader: any) => {
        var text = reader.result?.toString();

        const { result } = event.target;
        const workbook = xlsx.read(result, { type: "binary" });

        var allLines = [];
        if (workbook && workbook.SheetNames) {
            for (const Sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(Sheet)) {
                    text = xlsx.utils.sheet_to_csv(workbook.Sheets[Sheet], { FS: ";" });
                }
            }
        }

        allLines = text!.split((/\r|\n|\r/));

        if (!allLines) {
            showError(UploadErrorType.EmptyFile);
            return;
        }

        allLines.shift();

        if (allLines.length === 0) {
            showError(UploadErrorType.EmptyFile);
            return;
        }

        const membersArray: WebApiMemberDtoAddMemberObjectDto[] = [];
        const validMembersArray: WebApiMemberDtoAddMemberObjectDto[] = [];
        allLines.forEach(function (line: any) {
            const memberInFile = line.split(';');

            if (!memberInFile[0] && !memberInFile[1] && !memberInFile[2] && !memberInFile[3]) {
                return;
            }

            const member: WebApiMemberDtoAddMemberObjectDto = {
                uniqueId: trimFromCsv(memberInFile[0]),
                firstName: trimFromCsv(memberInFile[1]),
                lastName: trimFromCsv(memberInFile[2]),
                email: trimFromCsv(memberInFile[3]),
                email2: trimFromCsv(memberInFile[4]),
                phoneNumber: trimFromCsv(memberInFile[5]),
                address: trimFromCsv(memberInFile[6]),
                postalCode: trimFromCsv(memberInFile[7]),
                postalArea: trimFromCsv(memberInFile[8]),
                title: trimFromCsv(memberInFile[9]),
                country: trimFromCsv(memberInFile[10]),
                company: trimFromCsv(memberInFile[11]),
                extra1: trimFromCsv(memberInFile[12]),
                extra2: trimFromCsv(memberInFile[13]),
                extra3: trimFromCsv(memberInFile[14]),
                groups: trimFromCsv(memberInFile[15]),
                status: mapToMemberStatus(trimFromCsv(memberInFile[16])),
                paymentAmountYearMinusTwo: Number(trimFromCsv(memberInFile[17])),
                paymentAmountYearMinusOne: Number(trimFromCsv(memberInFile[18])),
                paymentAmountCurrentYear: Number(trimFromCsv(memberInFile[19])),
                paymentAmountNextYear: Number(trimFromCsv(memberInFile[20])),
            };

            membersArray.push(member);

            if (member.email!.length > 0 && !isEmail(member.email!)) {
                // validate e-mail
            }

            // uniqueid or email already exist
            if (membersArray.filter(p => p.uniqueId == member.uniqueId || p.email == member.email) || props.members.filter((m: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto) => m.uniqueId == member.uniqueId || m.email == member.email2)) {
                return;
            }

            if (member.email!.length === 0 || isEmail(member.email!)) {
                validMembersArray.push(member);
            }
        });

        if (props.user.subscriptionLevel == SubscriptionLevel.Free && (allLines.length + props.totalMemberCount) > 25) {
            showError(UploadErrorType.FreeTooManyUsers);
            return;
        };

        if (user.hasPaidSubscription && (allLines.length + props.totalMemberCount) > 999) {
            showError(UploadErrorType.PremiumTooManyUsers);
            return;
        };

        setMembers(membersArray);
        setValidMembers(validMembersArray);
        setOpen(true);
    };


    return (
        <>
            <Upload
                accept=".txt, .csv, .xlsx"
                showUploadList={false}
                beforeUpload={file => {
                    const reader = new FileReader();

                    reader.onload = async e => {
                        onFileUpload(e, reader);
                    };
                    reader.readAsBinaryString(file);
                    return false;
                }}
            >
                <BookyButton type="primary" style={{ width: "250px" }} icon={<FileOutlined />} text={GetText("Common_ChooseFile")}>
                </BookyButton>

            </Upload>
            <Modal
                open={open}
                title={GetText("UploadMembers_ModalTitlePreview")}
                okText={error == UploadErrorType.Unspecified ? GetText("UploadMembers_ModalAdd") + members.length + GetText("UploadMembers_ModalMembers") : GetText("UploadMembers_ModalOk")}
                cancelText={GetText("UploadMembers_ModalCancel")}
                width={1800}
                confirmLoading={isLoading}
                onCancel={() => { CloseModalAndShowNothing(); }}
                onOk={async () => {
                    setIsLoading(true);
                    if (validMembers.length > 0 && error == UploadErrorType.Unspecified) {
                        await MemberService.AddUsersInBulk({ members: validMembers });
                        CloseModalAndClearState();
                    }
                    CloseModalAndClearState();
                }}
            >
                {error == UploadErrorType.Unspecified ?
                    <>
                        <i>{GetText("UploadMembers_FoundInFile1")} {members.length} {GetText("UploadMembers_FoundInFile2")} {members.length - validMembers.length} {GetText("UploadMembers_FoundInFile3")}</i>
                        <UploadMemberPreviewData members={members} />
                    </>
                    : ""}
            </Modal>
            {errorText ? <Alert style={{ marginTop: "20px" }} showIcon={true} type="error" message={errorText}></Alert> : ""}
            {successText ? <Alert style={{ marginTop: "20px" }} showIcon={true} type="success" message={successText}></Alert> : ""}
        </>
    );
};

export default UploadMemberButton;