import { useEffect, useState } from 'react';
import { Modal, Space } from 'antd';
import { GroupService } from '../../Services/GroupService';
import { WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../Repository/eventbookyapi';
import CreateGroupButton from '../../Components/Users/CreateGroupButton';
import { AuthProvider } from '../../Providers/authProvider';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { TextService } from '../../Services/TextService';
import LeftMenu from './LeftMenu';
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/lib/table';
import { EditGroup } from '../../Components/Users/EditGroup';
import Paragraph from 'antd/lib/typography/Paragraph';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';

const AdminGroups = () => {
  const [groups, setGroups] = useState([] as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto[]);
  const [user, setUser] = useState({} as any);
  const GetText = TextService.GetText;
  const { confirm } = Modal;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    reloadGroup();
  }, []);

  const deleteGroup = async (id: number) => {
    confirm({
      title: GetText("Groups_DeleteConfirm"),
      icon: <ExclamationCircleFilled />,
      content: GetText("Groups_DeleteConfirmMessage"),
      async onOk() {
        await GroupService.DeleteGroup(id);
        reloadGroup();
      },
      onCancel() { },
    });
  }



  const columns: ColumnsType<WebApiServicesDataTransferObjectsGroupServiceGroupResultDto> = [
    {
      title: GetText("Group_NameColumn"),
      dataIndex: 'uniqueId',
      key: 'uniqueId',
      render: (_, group: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto) => (
        <span><b>{group.name}</b><br />
        </span>
      ),
    },
    {
      title: GetText("Group_NumberOfMembers"),
      dataIndex: 'numberOfMembers',
      key: 'numberOfMembers',
      render: (_, group: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto) => (
        <span><b>{group.numberOfMembers}</b><br />
        </span>
      ),
    },
    {
      title: GetText("MemberList_ActionColumn"),
      key: 'action',
      render: (_, group) => (
        <Space size="middle">
          {<EditGroup loadGroups={reloadGroup} groups={groups} group={group}>Edit</EditGroup>}

          <DeleteOutlined style={{ color: "red", fontSize: "20px" }} onClick={() => deleteGroup(group.id!)}></DeleteOutlined>
        </Space>
      ),
    },]

  const reloadGroup = () => {
    GroupService.GetGroups().then(result => {
      setGroups(result);
    });
  }

  return <LayoutLeftMenu title={GetText("Groups_GroupsTitle")} menu={<LeftMenu selected={"groups"}></LeftMenu>}><Space>
    <Paragraph>{GetText("Groups_Description")}</Paragraph>
  </Space>
    {user.subscriptionLevel == SubscriptionLevel.Free && groups.length == 1 ?
      <i>{GetText("Groups_UpgradeTo")} <a href='/Pricing'>Premium</a> {GetText("Groups_UpgradeTo2")}</i> :
      <CreateGroupButton user={user} reloadGroup={reloadGroup} />}

    <Table columns={columns} style={{ marginTop: "20px" }} locale={{
      emptyText: GetText("Groups_NoGroupsCreated")
    }} className="admin-table" rowKey="Id" dataSource={groups} />
  </LayoutLeftMenu>
};

export default AdminGroups;