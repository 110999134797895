import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, message } from 'antd';
import { BookyInput } from '../Forms/BookyInput';
import { EditOutlined } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';
import { WebApiCompanyDtoCompanyAdministratorResultDto, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../Repository/eventbookyapi';
import { GroupService } from '../../Services/GroupService';

export const EditGroup = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const GetText = TextService.GetText;
    const [admins, setAdmins] = useState([] as WebApiCompanyDtoCompanyAdministratorResultDto);

    const onCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        form.setFieldValue("groupName", props.group.name);
    }, [props]);

    const setFieldCustomError = (fieldName: string, error: string) => {
        form.setFields([
            {
                name: fieldName,
                errors: [error],
            },
        ]);
    };

    const onEdit = async (values: any) => {

        let foundDuplicate = false;

        props.groups.forEach((group: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto) => {
            if (group.name == values.groupName) {
                setFieldCustomError("groupName", GetText("EditGroup_NameExist"));
                foundDuplicate = true;
            }
        });

        if (foundDuplicate) {
            return;
        }
        try {
            await GroupService.EditGroup({
                id: props.group.id,
                groupName: values.groupName
            });
        } catch (error) {
            message.warning(GetText("EditGroup_Error"));
            return;
        }
        setOpen(false);

        props.loadGroups();
    };

    return (
        <>
            <Modal
                open={open}
                title={GetText("EditMember_ModalTitle")}
                okText={GetText("EditMember_ModalOk")}
                cancelText={GetText("EditMember_ModalCancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            onEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form form={form} labelAlign={"left"} labelCol={{ span: 8, offset: 0 }} wrapperCol={{ span: 16 }} onFinish={onEdit}>
                    <Col span={17}>
                        <BookyInput label={GetText("EditGroup_GroupName")} requiredMessage={GetText("CreateGroup_RequiredText")} name="groupName" />
                    </Col>
                </Form>
            </Modal>

            <EditOutlined
                style={{ fontSize: "20px" }}
                type="primary"
                onClick={() => {
                    setOpen(true);
                }}
            >
            </EditOutlined>
        </>
    );
};
