/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiEmailDtoEmailEventDto } from './WebApiEmailDtoEmailEventDto';
import {
    WebApiEmailDtoEmailEventDtoFromJSON,
    WebApiEmailDtoEmailEventDtoFromJSONTyped,
    WebApiEmailDtoEmailEventDtoToJSON,
} from './WebApiEmailDtoEmailEventDto';

/**
 * 
 * @export
 * @interface WebApiEmailDtoEmailReportDto
 */
export interface WebApiEmailDtoEmailReportDto {
    /**
     * 
     * @type {WebApiEmailDtoEmailEventDto}
     * @memberof WebApiEmailDtoEmailReportDto
     */
    event?: WebApiEmailDtoEmailEventDto;
    /**
     * 
     * @type {Array<WebApiEmailDtoEmailEventDto>}
     * @memberof WebApiEmailDtoEmailReportDto
     */
    subEvents?: Array<WebApiEmailDtoEmailEventDto> | null;
}

/**
 * Check if a given object implements the WebApiEmailDtoEmailReportDto interface.
 */
export function instanceOfWebApiEmailDtoEmailReportDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiEmailDtoEmailReportDtoFromJSON(json: any): WebApiEmailDtoEmailReportDto {
    return WebApiEmailDtoEmailReportDtoFromJSONTyped(json, false);
}

export function WebApiEmailDtoEmailReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiEmailDtoEmailReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event': !exists(json, 'event') ? undefined : WebApiEmailDtoEmailEventDtoFromJSON(json['event']),
        'subEvents': !exists(json, 'subEvents') ? undefined : (json['subEvents'] === null ? null : (json['subEvents'] as Array<any>).map(WebApiEmailDtoEmailEventDtoFromJSON)),
    };
}

export function WebApiEmailDtoEmailReportDtoToJSON(value?: WebApiEmailDtoEmailReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event': WebApiEmailDtoEmailEventDtoToJSON(value.event),
        'subEvents': value.subEvents === undefined ? undefined : (value.subEvents === null ? null : (value.subEvents as Array<any>).map(WebApiEmailDtoEmailEventDtoToJSON)),
    };
}

