/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiEmailDtoEmailReportDto } from './WebApiEmailDtoEmailReportDto';
import {
    WebApiEmailDtoEmailReportDtoFromJSON,
    WebApiEmailDtoEmailReportDtoFromJSONTyped,
    WebApiEmailDtoEmailReportDtoToJSON,
} from './WebApiEmailDtoEmailReportDto';

/**
 * 
 * @export
 * @interface WebApiEmailDtoEmailEventReportDto
 */
export interface WebApiEmailDtoEmailEventReportDto {
    /**
     * 
     * @type {Array<WebApiEmailDtoEmailReportDto>}
     * @memberof WebApiEmailDtoEmailEventReportDto
     */
    events?: Array<WebApiEmailDtoEmailReportDto> | null;
}

/**
 * Check if a given object implements the WebApiEmailDtoEmailEventReportDto interface.
 */
export function instanceOfWebApiEmailDtoEmailEventReportDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiEmailDtoEmailEventReportDtoFromJSON(json: any): WebApiEmailDtoEmailEventReportDto {
    return WebApiEmailDtoEmailEventReportDtoFromJSONTyped(json, false);
}

export function WebApiEmailDtoEmailEventReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiEmailDtoEmailEventReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': !exists(json, 'events') ? undefined : (json['events'] === null ? null : (json['events'] as Array<any>).map(WebApiEmailDtoEmailReportDtoFromJSON)),
    };
}

export function WebApiEmailDtoEmailEventReportDtoToJSON(value?: WebApiEmailDtoEmailEventReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': value.events === undefined ? undefined : (value.events === null ? null : (value.events as Array<any>).map(WebApiEmailDtoEmailReportDtoToJSON)),
    };
}

