import { Tag } from 'antd';
import { TextService } from '../../Services/TextService';
import { WebApiEmailDtoEmailReportDto } from '../../Repository/eventbookyapi';
import Table, { ColumnsType } from 'antd/lib/table';
import { formatDateString } from '../../Helpers/DateHelpers';
import { EmailEventType } from './EmailEventType';

const AdminEmailLogsTable = (props: any) => {
    const GetText = TextService.GetText;

    const columns: ColumnsType<WebApiEmailDtoEmailReportDto> = [
        {
            title: GetText("EmailLogs_Date"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiEmailDtoEmailReportDto) => (
                <span>{formatDateString(member.event!.date!.toString())}<br />
                </span>
            ),
        },
        {
            title: GetText("EmailLogs_Email"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiEmailDtoEmailReportDto) => (
                <span>{member.event!.email}<br />
                </span>
            ),
        },
        {
            title: GetText("EmailLogs_Subject"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiEmailDtoEmailReportDto) => (
                <span>{member.event!.subject}<br />
                </span>
            ),
        },
        {
            title: GetText("EmailLogs_From"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiEmailDtoEmailReportDto) => (
                <span>{member.event!.from}<br />
                </span>
            ),
        },
        {
            title: GetText("EmailLogs_Status"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiEmailDtoEmailReportDto) => (
                <Tag color={mapColor(member.event!.event as EmailEventType)}>{mapText(member.event!.event as EmailEventType)}</Tag>
            ),
        },
        {
            title: GetText("EmailLogs_Reason"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiEmailDtoEmailReportDto) => (
                <span>{member.event!.reason}<br />
                </span>
            ),
        }
    ];

    const mapColor = (event: EmailEventType) => {
        switch (event) {
            case EmailEventType.Delivered:
            case EmailEventType.Opened:
            case EmailEventType.Clicks:
                return "green";
            case EmailEventType.Bounces:
            case EmailEventType.HardBounces:
            case EmailEventType.SoftBounces:
            case EmailEventType.Spam:
            case EmailEventType.Deferred:
            case EmailEventType.Blocked:
                return "orange";
            case EmailEventType.Error:
            case EmailEventType.Invalid:
                return "red";
            default:
                return "blue"
        }
    }

    const mapText = (event: EmailEventType) => {
        switch (event) {
            case EmailEventType.Requests:
                return GetText("EmailLogs_Requests");
            case EmailEventType.Delivered:
                return GetText("EmailLogs_Delivered");
            case EmailEventType.Opened:
                return GetText("EmailLogs_Opened");
            case EmailEventType.Clicks:
                return GetText("EmailLogs_Clicks");
            case EmailEventType.Bounces:
                return GetText("EmailLogs_Bounce");
            case EmailEventType.HardBounces:
                return GetText("EmailLogs_HardBounces");
            case EmailEventType.SoftBounces:
                return GetText("EmailLogs_SoftBounces");
            case EmailEventType.Spam:
                return GetText("EmailLogs_Spam");
            case EmailEventType.Deferred:
                return GetText("EmailLogs_Defered");
            case EmailEventType.Blocked:
                return GetText("EmailLogs_Blocked");
            case EmailEventType.Error:
                return GetText("EmailLogs_Error");
            case EmailEventType.Invalid:
                return GetText("EmailLogs_Error");
            default:
                return event.toString();
        }
    }


    return <Table
        loading={props.isLoading}
        columns={columns}
        locale={{ emptyText: GetText("EmailLogs_EmptyMessage") }}
        rowKey={m => m.event?.messageId!}
        pagination={{ position: ['topRight'] }}
        expandable={{
            expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.subEvents?.map(p =>
                <div>{formatDateString(p.date!.toString())} <Tag color={mapColor(p.event as EmailEventType)}>{mapText(p.event as EmailEventType)}</Tag></div>)}</p>,
            rowExpandable: (record) => record.subEvents!.length > 0,
        }}
        key='memberId'
        dataSource={props.logs}
    />
};

export default AdminEmailLogsTable;