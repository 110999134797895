import { useEffect, useState } from 'react';
import { WebApiMemberDtoMemberHistoryDto } from '../../Repository/eventbookyapi';
import { AuthProvider } from '../../Providers/authProvider';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { TextService } from '../../Services/TextService';
import LeftMenu from './LeftMenu';
import Table, { ColumnsType } from 'antd/lib/table';
import { MemberService } from '../../Services/MemberService';
import { formatDateString } from '../../Helpers/DateHelpers';
import { mapHistoryEventTypeToText } from '../../Mappers/mapHistoryEvents';
import { mapHistoryeToText } from '../../Mappers/mapHistoryEventText';
import { mapDynamicInputLabel } from '../../Mappers/mapDynamicInputLabel';
import { GetStringIfNotEmpty } from '../../Helpers/StringHelper';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';

const AdminUserHistory = () => {
  const [history, setHistory] = useState([] as WebApiMemberDtoMemberHistoryDto[]);
  const [user, setUser] = useState({} as any);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    MemberService.GetMemberHistory().then(memberHistory => {
      setHistory(memberHistory);
    });
  }, []);


  const columns: ColumnsType<WebApiMemberDtoMemberHistoryDto> = [
    {
      title: GetText("History_Date"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, history: WebApiMemberDtoMemberHistoryDto) => (
        <span><b>{formatDateString(history.date!.toString())}</b><br />
        </span>
      ),
    },
    {
      title: GetText("History_EventType"),
      dataIndex: 'numberOfMembers',
      key: 'numberOfMembers',
      render: (_, history: WebApiMemberDtoMemberHistoryDto) => (
        <span>{mapHistoryEventTypeToText(Number(history.type))}<br />
        </span>
      ),
    },
    {
      title: GetText("History_EventText"),
      key: 'action',
      render: (_, history) => (
        <span>{mapHistoryeToText(history)}<br />
        </span>
      ),
    },]

  return <LayoutLeftMenu title={GetText("Users_UserHistoryTitle")} menu={<LeftMenu selected={"userHistory"}></LeftMenu>}>
    {user.subscriptionLevel == SubscriptionLevel.Free ?
      <i>{GetText("Groups_UpgradeTo")} <a href='/Pricing'>Premium</a></i> :

      <Table columns={columns} style={{ marginTop: "20px" }} locale={{
        emptyText: GetText("History_NoHistoryYet")
      }} className="admin-table" rowKey="id" dataSource={history}
        expandable={{
          expandedRowRender: (record) => <table className='version-history-table'><thead><tr><th>{GetText("History_Field")}</th><th>{GetText("History_OldValue")}</th><th>{GetText("History_NewValue")}</th></tr></thead>

            {record.changes?.map(p =>
              <tr><td>{mapDynamicInputLabel(p.memberFieldTypes!)}</td> <td>{GetStringIfNotEmpty(p.oldValue)}</td> <td>{GetStringIfNotEmpty(p.newValue)}</td></tr>)}</table>,
          rowExpandable: (record) => record.changes!.length > 0,
        }}
      />
    }
  </LayoutLeftMenu>
};


export default AdminUserHistory