import { ApiMemberAddMemberPostRequest, ApiMemberMemberIdDeleteRequest, ApiMemberSendMailToMembersPostRequest, MemberApi, WebApiMemberDtoAddBulkMemberDto, WebApiMemberFieldsDtoSaveMemberFieldSettingsDto, WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData, WebApiServicesDataTransferObjectsGroupServiceAddMemberDto, WebApiServicesDataTransferObjectsGroupServiceAddNoteDto, WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto, WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto, WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class MemberService {
    public static AddMember = async (member: WebApiServicesDataTransferObjectsGroupServiceAddMemberDto) => {
        const request: ApiMemberAddMemberPostRequest = { webApiServicesDataTransferObjectsGroupServiceAddMemberDto: member };
        return new MemberApi(GetApiConfiguration()).apiMemberAddMemberPost(request);
    };

    public static DeleteMember = async (memberId: number) => {
        const request: ApiMemberMemberIdDeleteRequest = { memberId };
        return new MemberApi(GetApiConfiguration()).apiMemberMemberIdDelete(request);
    };


    public static UpdateMember = async (request: WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto) => {
        return new MemberApi(GetApiConfiguration()).apiMemberUpdateMemberPost({
            webApiServicesDataTransferObjectsGroupServiceUpdateMemberDto: request
        });
    };

    public static AddUsersInBulk = async (request: WebApiMemberDtoAddBulkMemberDto) => {
        return new MemberApi(GetApiConfiguration()).apiMemberAddMembersInBulkPost({
            webApiMemberDtoAddBulkMemberDto: request
        });
    };

    public static AddPaymentsInBulk = async (request: WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData) => {
        return new MemberApi(GetApiConfiguration()).apiMemberAddpaymentsInBulkPost({
            webApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData: request
        });
    };

    public static GetAllMembers = async () => {
        return new MemberApi(GetApiConfiguration()).apiMemberGet();
    };

    public static GetMemberHistory = async () => {
        return new MemberApi(GetApiConfiguration()).apiMemberMemberHistoryByCompanyGet();
    };

    public static GetFilteredMembers = async (request: WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto) => {
        return new MemberApi(GetApiConfiguration()).apiMemberGetFilteredMembersPost({
            webApiServicesDataTransferObjectsMemberServiceQueryMemberDto: request
        });
    };

    public static GetNotes = async (memberId: number) => {
        return new MemberApi(GetApiConfiguration()).apiMemberMemberIdNotesGet({
            memberId
        });
    };

    public static AddNote = async (addNote: WebApiServicesDataTransferObjectsGroupServiceAddNoteDto) => {
        return new MemberApi(GetApiConfiguration()).apiMemberNotePost({
            webApiServicesDataTransferObjectsGroupServiceAddNoteDto: addNote
        });
    };

    public static DeleteNote = async (noteId: number) => {
        return new MemberApi(GetApiConfiguration()).apiMemberNoteNoteIdDelete({
            noteId
        });
    };

    public static GetMembersAsFile = async () => {
        return new MemberApi(GetApiConfiguration()).apiMemberGenerateExcelFromMembersGetRaw();
    };

    public static SendEmailToMembers = async (request: ApiMemberSendMailToMembersPostRequest) => {
        return new MemberApi(GetApiConfiguration()).apiMemberSendMailToMembersPost(
            request
        );
    };

    public static GetMemberFields = async () => {
        return new MemberApi(GetApiConfiguration()).apiMemberGetMemberFieldsGet();
    };

    public static SaveMemberFields = async (request: WebApiMemberFieldsDtoSaveMemberFieldSettingsDto) => {
        return new MemberApi(GetApiConfiguration()).apiMemberSaveMemberFieldsPost({
            webApiMemberFieldsDtoSaveMemberFieldSettingsDto: request
        });
    };

    public static SaveOrUpdatePayment = async (request: WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto) => {
        return new MemberApi(GetApiConfiguration()).apiMemberSavePaymentPost({
            webApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto: request
        });
    };

    public static GetPayments = async (memberId: number) => {
        return new MemberApi(GetApiConfiguration()).apiMemberGetPaymentsGet({ memberId });
    };

    public static DeletePayment = async (paymentId: number) => {
        return new MemberApi(GetApiConfiguration()).apiMemberPaymentPaymentIdDelete({
            paymentId
        });
    };
};
