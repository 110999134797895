/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiCompanyDtoCompanyAddUserDto,
  WebApiCompanyDtoCompanyAddUserFromInviteDto,
  WebApiCompanyDtoCompanyAdministratorResultDto,
  WebApiCompanyDtoCompanyDto,
  WebApiCompanyDtoCompanyPublicSiteDto,
  WebApiCompanyDtoCompanyUpdatePublicSiteDto,
  WebApiCompanyDtoCompanyUpdateUserDto,
  WebApiCompanyDtoCompanyUserDto,
  WebApiEmailDtoDomainAuthenticationResultDto,
  WebApiEmailDtoEmailDomainCreationDto,
  WebApiModelsEnumerationTypesSubscriptionLevel,
  WebApiPaymentRepositoryPaysonDtoConfirmResponse,
  WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse,
} from '../models';
import {
    WebApiCompanyDtoCompanyAddUserDtoFromJSON,
    WebApiCompanyDtoCompanyAddUserDtoToJSON,
    WebApiCompanyDtoCompanyAddUserFromInviteDtoFromJSON,
    WebApiCompanyDtoCompanyAddUserFromInviteDtoToJSON,
    WebApiCompanyDtoCompanyAdministratorResultDtoFromJSON,
    WebApiCompanyDtoCompanyAdministratorResultDtoToJSON,
    WebApiCompanyDtoCompanyDtoFromJSON,
    WebApiCompanyDtoCompanyDtoToJSON,
    WebApiCompanyDtoCompanyPublicSiteDtoFromJSON,
    WebApiCompanyDtoCompanyPublicSiteDtoToJSON,
    WebApiCompanyDtoCompanyUpdatePublicSiteDtoFromJSON,
    WebApiCompanyDtoCompanyUpdatePublicSiteDtoToJSON,
    WebApiCompanyDtoCompanyUpdateUserDtoFromJSON,
    WebApiCompanyDtoCompanyUpdateUserDtoToJSON,
    WebApiCompanyDtoCompanyUserDtoFromJSON,
    WebApiCompanyDtoCompanyUserDtoToJSON,
    WebApiEmailDtoDomainAuthenticationResultDtoFromJSON,
    WebApiEmailDtoDomainAuthenticationResultDtoToJSON,
    WebApiEmailDtoEmailDomainCreationDtoFromJSON,
    WebApiEmailDtoEmailDomainCreationDtoToJSON,
    WebApiModelsEnumerationTypesSubscriptionLevelFromJSON,
    WebApiModelsEnumerationTypesSubscriptionLevelToJSON,
    WebApiPaymentRepositoryPaysonDtoConfirmResponseFromJSON,
    WebApiPaymentRepositoryPaysonDtoConfirmResponseToJSON,
    WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponseFromJSON,
    WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponseToJSON,
} from '../models';

export interface ApiCompanyAddAdministratorPostRequest {
    webApiCompanyDtoCompanyAddUserDto?: WebApiCompanyDtoCompanyAddUserDto;
}

export interface ApiCompanyConfirmPaymentGetRequest {
    id?: number;
}

export interface ApiCompanyConnectUserToCompanyPostRequest {
    webApiCompanyDtoCompanyAddUserFromInviteDto?: WebApiCompanyDtoCompanyAddUserFromInviteDto;
}

export interface ApiCompanyDeleteAdministratorCompanyUserIdDeleteRequest {
    companyUserId: number;
}

export interface ApiCompanyGetCompanyUserInviteByIdInviteIdGetRequest {
    inviteId: string;
}

export interface ApiCompanyInitiatePaymentGetRequest {
    level?: WebApiModelsEnumerationTypesSubscriptionLevel;
}

export interface ApiCompanyPaymentNotificationIdPostRequest {
    id: number;
}

export interface ApiCompanyPostRequest {
    webApiCompanyDtoCompanyDto?: WebApiCompanyDtoCompanyDto;
}

export interface ApiCompanyUpdateAdministratorPostRequest {
    webApiCompanyDtoCompanyUpdateUserDto?: WebApiCompanyDtoCompanyUpdateUserDto;
}

export interface ApiCompanyUpdateCompanyPublicSitePostRequest {
    webApiCompanyDtoCompanyUpdatePublicSiteDto?: WebApiCompanyDtoCompanyUpdatePublicSiteDto;
}

/**
 * 
 */
export class CompanyApi extends runtime.BaseAPI {

    /**
     * Add new administrator
     */
    async apiCompanyAddAdministratorPostRaw(requestParameters: ApiCompanyAddAdministratorPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/AddAdministrator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiCompanyDtoCompanyAddUserDtoToJSON(requestParameters.webApiCompanyDtoCompanyAddUserDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add new administrator
     */
    async apiCompanyAddAdministratorPost(requestParameters: ApiCompanyAddAdministratorPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiCompanyAddAdministratorPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connects company adress as domain sender
     */
    async apiCompanyAddDomainPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiEmailDtoEmailDomainCreationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/AddDomain`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiEmailDtoEmailDomainCreationDtoFromJSON(jsonValue));
    }

    /**
     * Connects company adress as domain sender
     */
    async apiCompanyAddDomainPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiEmailDtoEmailDomainCreationDto> {
        const response = await this.apiCompanyAddDomainPostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Connects company adress as domain sender
     */
    async apiCompanyAuthenticateDomainPutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiEmailDtoDomainAuthenticationResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/AuthenticateDomain`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiEmailDtoDomainAuthenticationResultDtoFromJSON(jsonValue));
    }

    /**
     * Connects company adress as domain sender
     */
    async apiCompanyAuthenticateDomainPut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiEmailDtoDomainAuthenticationResultDto> {
        const response = await this.apiCompanyAuthenticateDomainPutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Authenticates the user.
     */
    async apiCompanyConfirmPaymentGetRaw(requestParameters: ApiCompanyConfirmPaymentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiPaymentRepositoryPaysonDtoConfirmResponse>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/ConfirmPayment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiPaymentRepositoryPaysonDtoConfirmResponseFromJSON(jsonValue));
    }

    /**
     * Authenticates the user.
     */
    async apiCompanyConfirmPaymentGet(requestParameters: ApiCompanyConfirmPaymentGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiPaymentRepositoryPaysonDtoConfirmResponse> {
        const response = await this.apiCompanyConfirmPaymentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authenticates the user.
     */
    async apiCompanyConnectUserToCompanyPostRaw(requestParameters: ApiCompanyConnectUserToCompanyPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/ConnectUserToCompany`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiCompanyDtoCompanyAddUserFromInviteDtoToJSON(requestParameters.webApiCompanyDtoCompanyAddUserFromInviteDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Authenticates the user.
     */
    async apiCompanyConnectUserToCompanyPost(requestParameters: ApiCompanyConnectUserToCompanyPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiCompanyConnectUserToCompanyPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiCompanyDeleteAdministratorCompanyUserIdDeleteRaw(requestParameters: ApiCompanyDeleteAdministratorCompanyUserIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.companyUserId === null || requestParameters.companyUserId === undefined) {
            throw new runtime.RequiredError('companyUserId','Required parameter requestParameters.companyUserId was null or undefined when calling apiCompanyDeleteAdministratorCompanyUserIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/DeleteAdministrator/{companyUserId}`.replace(`{${"companyUserId"}}`, encodeURIComponent(String(requestParameters.companyUserId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiCompanyDeleteAdministratorCompanyUserIdDelete(requestParameters: ApiCompanyDeleteAdministratorCompanyUserIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiCompanyDeleteAdministratorCompanyUserIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connects company adress as domain sender
     */
    async apiCompanyDeleteDomainDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/DeleteDomain`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Connects company adress as domain sender
     */
    async apiCompanyDeleteDomainDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiCompanyDeleteDomainDeleteRaw(initOverrides);
    }

    /**
     * Get company
     */
    async apiCompanyGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiCompanyDtoCompanyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiCompanyDtoCompanyDtoFromJSON(jsonValue));
    }

    /**
     * Get company
     */
    async apiCompanyGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiCompanyDtoCompanyDto> {
        const response = await this.apiCompanyGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Authenticates the user.
     */
    async apiCompanyGetAdministratorsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiCompanyDtoCompanyAdministratorResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetAdministrators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiCompanyDtoCompanyAdministratorResultDtoFromJSON(jsonValue));
    }

    /**
     * Authenticates the user.
     */
    async apiCompanyGetAdministratorsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiCompanyDtoCompanyAdministratorResultDto> {
        const response = await this.apiCompanyGetAdministratorsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Public site
     */
    async apiCompanyGetCompanyPublicSiteGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiCompanyDtoCompanyPublicSiteDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetCompanyPublicSite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiCompanyDtoCompanyPublicSiteDtoFromJSON(jsonValue));
    }

    /**
     * Get Public site
     */
    async apiCompanyGetCompanyPublicSiteGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiCompanyDtoCompanyPublicSiteDto> {
        const response = await this.apiCompanyGetCompanyPublicSiteGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets user by an invite
     */
    async apiCompanyGetCompanyUserInviteByIdInviteIdGetRaw(requestParameters: ApiCompanyGetCompanyUserInviteByIdInviteIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiCompanyDtoCompanyUserDto>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling apiCompanyGetCompanyUserInviteByIdInviteIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetCompanyUserInviteById/{inviteId}`.replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiCompanyDtoCompanyUserDtoFromJSON(jsonValue));
    }

    /**
     * Gets user by an invite
     */
    async apiCompanyGetCompanyUserInviteByIdInviteIdGet(requestParameters: ApiCompanyGetCompanyUserInviteByIdInviteIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiCompanyDtoCompanyUserDto> {
        const response = await this.apiCompanyGetCompanyUserInviteByIdInviteIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connects company adress as domain sender
     */
    async apiCompanyGetDomainGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiEmailDtoEmailDomainCreationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetDomain`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiEmailDtoEmailDomainCreationDtoFromJSON(jsonValue));
    }

    /**
     * Connects company adress as domain sender
     */
    async apiCompanyGetDomainGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiEmailDtoEmailDomainCreationDto> {
        const response = await this.apiCompanyGetDomainGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Initiaties payment with level
     */
    async apiCompanyInitiatePaymentGetRaw(requestParameters: ApiCompanyInitiatePaymentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse>> {
        const queryParameters: any = {};

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/InitiatePayment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponseFromJSON(jsonValue));
    }

    /**
     * Initiaties payment with level
     */
    async apiCompanyInitiatePaymentGet(requestParameters: ApiCompanyInitiatePaymentGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse> {
        const response = await this.apiCompanyInitiatePaymentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Notifications sent from Payson
     */
    async apiCompanyPaymentNotificationIdPostRaw(requestParameters: ApiCompanyPaymentNotificationIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyPaymentNotificationIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/PaymentNotification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponseFromJSON(jsonValue));
    }

    /**
     * Notifications sent from Payson
     */
    async apiCompanyPaymentNotificationIdPost(requestParameters: ApiCompanyPaymentNotificationIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse> {
        const response = await this.apiCompanyPaymentNotificationIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update company
     */
    async apiCompanyPostRaw(requestParameters: ApiCompanyPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiCompanyDtoCompanyDtoToJSON(requestParameters.webApiCompanyDtoCompanyDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update company
     */
    async apiCompanyPost(requestParameters: ApiCompanyPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiCompanyPostRaw(requestParameters, initOverrides);
    }

    /**
     * Updates the administrator
     */
    async apiCompanyUpdateAdministratorPostRaw(requestParameters: ApiCompanyUpdateAdministratorPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/UpdateAdministrator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiCompanyDtoCompanyUpdateUserDtoToJSON(requestParameters.webApiCompanyDtoCompanyUpdateUserDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Updates the administrator
     */
    async apiCompanyUpdateAdministratorPost(requestParameters: ApiCompanyUpdateAdministratorPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiCompanyUpdateAdministratorPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update public site
     */
    async apiCompanyUpdateCompanyPublicSitePostRaw(requestParameters: ApiCompanyUpdateCompanyPublicSitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/UpdateCompanyPublicSite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiCompanyDtoCompanyUpdatePublicSiteDtoToJSON(requestParameters.webApiCompanyDtoCompanyUpdatePublicSiteDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update public site
     */
    async apiCompanyUpdateCompanyPublicSitePost(requestParameters: ApiCompanyUpdateCompanyPublicSitePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiCompanyUpdateCompanyPublicSitePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
