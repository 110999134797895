import { ApiEmailTemplateGetEmailLogsGetRequest, EmailTemplateApi, WebApiMemberDtoSendMailToExternalDto, WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto, WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class EmailTemplateService {
    public static CreateTemplate = async (request: WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplatePost({
            webApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto: request
        });
    };

    public static GetTemplates = async () => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateGet();
    };


    public static DeleteTemplate = async (templateId: number) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateTemplateIdDelete({
            templateId: templateId
        });
    };

    public static EditTemplate = async (request: WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateUpdatePost({
            webApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto: request
        });
    };

    public static SendMailToExternal = async (request: WebApiMemberDtoSendMailToExternalDto) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateSendMailToExternalPost({
            webApiMemberDtoSendMailToExternalDto: request
        });
    };

    public static GetEmailLogs = async (request: ApiEmailTemplateGetEmailLogsGetRequest) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateGetEmailLogsGet(
            request
        );
    };
};
