import { useEffect, useState } from 'react';
import { TextService } from '../../../Services/TextService';
import { AuthProvider } from '../../../Providers/authProvider';
import { MemberService } from '../../../Services/MemberService';
import LeftMenu from '../../../PagesAdmin/Users/LeftMenu';
import Paragraph from 'antd/lib/typography/Paragraph';
import { WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../../Repository/eventbookyapi';
import UploadMemberButton from './UploadMemberButton';
import { getCurrentLanguage } from '../../../Helpers/RouteHelper';
import LayoutLeftMenu from '../../Layout/LayoutLeftMenu';
import { BookyTitleWithIcon } from '../../Forms/TitleWithIcon';
import { Col, message } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { BookyButton } from '../../Buttons/BookyButton';
import { SubscriptionLevel } from '../../../Types/SubscriptionLevel';


const UploadMembers = () => {
    const [memberResult, setMemberResult] = useState({} as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto);
    const [user, setUser] = useState({} as any);
    const GetText = TextService.GetText;
    const [showFullPageSpinner, setShowFullPageSpinner] = useState(false as boolean);

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        });
    }, []);


    const downloadExcel = async () => {
        if (user?.subscriptionLevel === SubscriptionLevel.Free) {
            message.warning(GetText("MyEventDetails_SorryOnlyPremium"));
            return;
        };
        setShowFullPageSpinner(true);
        const data = await MemberService.GetMembersAsFile();
        const url = window.URL.createObjectURL(await data.raw.blob());
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Members_${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setShowFullPageSpinner(false);
    }

    useEffect(() => {
        loadMembers();
    }, []);

    const loadMembers = () => {
        MemberService.GetAllMembers().then(result => {
            setMemberResult(result);
        });
    }

    return <LayoutLeftMenu fullpageMain={true} menu={<LeftMenu selected={"users"}></LeftMenu>}>

        <Col span={16}>



            <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }}>
                <BookyTitleWithIcon text={GetText("Users_UsersFromFileTitle")}><UploadOutlined /></BookyTitleWithIcon>

                <Paragraph>{GetText("UploadMembers_UploadCsvDescription1")}<br />
                    {GetText("UploadMembers_UploadCsvDescription2")}
                </Paragraph>

                <a href={getCurrentLanguage() == "se" ? require('../UploadMembers/members_se.csv') : require('../UploadMembers/members_en.csv')}>{GetText("UploadMembers_CSV")}</a><br />
                <a href={getCurrentLanguage() == "se" ? require('../UploadMembers/members_se.xlsx') : require('../UploadMembers/members_en.xlsx')}>{GetText("UploadMembers_XLSX")}</a><br></br><br></br>
                <UploadMemberButton members={memberResult.members} user={user} />
            </Col>


            <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }}>
                <BookyTitleWithIcon text={"Exportera medlemmar till fil"}><DownloadOutlined /></BookyTitleWithIcon>
                <Paragraph>Här exporterar du hela din medlemslista inklusive betalningar som registrerats till en Excel fil.</Paragraph>
                <BookyButton type="primary" style={{ width: "250px" }} onClick={downloadExcel} icon={<DownloadOutlined />} text={GetText("MemberList_Download")} />
            </Col>
        </Col>




    </LayoutLeftMenu>
};

export default UploadMembers;