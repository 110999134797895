import { useEffect, useState } from 'react';
import { Col, Form, Space, Spin, message } from 'antd';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import LeftMenuCommunicate from './LeftCommunicate';
import { OneFrontDatePicker } from '../../Components/Forms/BookyDatePicker';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import Paragraph from 'antd/lib/typography/Paragraph';
import { EmailTemplateService } from '../../Services/EmailTemplateService';
import { WebApiEmailDtoEmailReportDto } from '../../Repository/eventbookyapi';
import { BookyInput } from '../../Components/Forms/BookyInput';
import AdminEmailLogsTable from './EmailLogsTable';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import { BookyCheckbox } from '../../Components/Forms/BookyCheckbox';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import dayjs from 'dayjs';


const AdminEmailLogs = () => {
  const [user, setUser] = useState({} as any);
  const [logs, setLogs] = useState([] as WebApiEmailDtoEmailReportDto[]);
  const [isLoading, setIsLoading] = useState(false as boolean);

  const [form] = Form.useForm();
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
      form.setFieldValue("startDate", dayjs().add(-7, 'days'));
      form.setFieldValue("endDate", dayjs());
      searchEmailFollowUp();
    });


  }, []);

  const searchEmailFollowUp = async () => {


    var a = dayjs(form.getFieldValue("startDate"));
    var b = dayjs(form.getFieldValue("endDate"));
    var diff = b.diff(a, 'days');


    if (diff < 0 || diff > 90) {
      message.error(GetText("EmailLogs_Max90Days"));
      return;
    }

    setIsLoading(true);
    const logs = await EmailTemplateService.GetEmailLogs({
      start: form.getFieldValue("startDate"),
      end: form.getFieldValue("endDate"),
      email: form.getFieldValue("email"),
      onlyError: form.getFieldValue("onlyError")
    });

    setIsLoading(false);

    if (logs.events) {
      setLogs(logs.events);
    }
    else {
      setLogs([]);
    }
  }

  const pageHelpContent_se = <>På denna sida hittar du loggen för skickad e-post. < span style={{ color: "green" }
  }> Grönt</span > visar om mailet gått iväg och vart i utskicksprocessen det befinner sig / har mottagits. < span style={{ color: "red" }}> Rött</span > visar att mailet av en eller annan anledning inte kommit fram. Oftast beror det på att mottagarens e-postleverantör inte släppt in e-post eller att mailadress inte längre existerar. Om problem, kontrollera med mottagaren om adressuppgifterna kan vara fel eller om dennes e-postleverantör kan ha ”svartlistat” oss som avsändare.
    <br /><br />
    <ul>
      <li><span style={{ color: "green" }}>Skickat</span>: Mail har skickats till mottagaren</li>
      <li><span style={{ color: "green" }}>Mottaget</span>: Mail har bekräftats levererat till mottagarens e-postserver</li>
      <li><span style={{ color: "green" }}>Första öppning</span>: Mottagaren öppnade mailet för första gången</li>
      <li><span style={{ color: "green" }}>Öppnat</span>: Mottagaren har öppnat mail</li>
      <li><span style={{ color: "red" }}>Studs</span>: Mailet kunde inte levereras</li>
      <li><span style={{ color: "red" }}>Hård studs</span>: Mailet kunde inte levereras på grund av ett permanent problem (t.ex. felstavad eller ej existerande e-postadress)</li>
      <li><span style={{ color: "red" }}>Mjuk studs</span>:Mailet kunde inte levereras på grund av ett tillfälligt problem (t.ex. e-postservern är tillfälligt otillgänglig eller full inkorg)</li>
      <li><span style={{ color: "red" }}>Blockerat</span>: Mottagaren har inte fått mail, e-postadressen är blockerad (e.g. svartlistad)</li>
      <li><span style={{ color: "red" }}>Skräppost</span>: Mailet rapporterades som skräppost av mottagaren</li>
      <li><span style={{ color: "red" }}>Avslutad</span>: Mottagaren avslutade prenumerationen från din e-postlista</li>
      <li><span style={{ color: "red" }}>Blockerat</span>: Mottagaren har inte fått mail, e-postadressen är blockerad (e.g. svartlistad)</li>
      <li><span style={{ color: "red" }}>Ogiltig e-postadress</span>: Mottagaren har inte fått mailet, e-postadressen finns ej</li>
      <li><span style={{ color: "red" }}>Stoppat</span>: Mailet är i standby-läge och kan tas emot senare (t.ex. full inkorg)</li>
      <li><span style={{ color: "red" }}>Fel inträffade</span>: Mottagaren har inte fått mailet, ett problem inträffade under sändningen via ett API-anrop</li>
    </ul></>

  const pageHelpContent_en = <>On this page you can find the log for sent email. < span style={{ color: "green" }
  }> Green</span > shows if email has been sent and where in the process the email is- < span style={{ color: "red" }}> Red</span > shows that the email for some reason has not been received correct. Most of the times the cause is the member email is not correct. Check the email if its correct.
    <br /><br />
    <ul>
      <li><span style={{ color: "green" }}>Sent</span>: Mail has been sent to receiver</li>
      <li><span style={{ color: "green" }}>Delivered</span>: Mail has been confiemed delivered by receiver email provider</li>
      <li><span style={{ color: "green" }}>First opening</span>: Receiver opened the mail for the first time</li>
      <li><span style={{ color: "green" }}>Opened</span>: Receiver have opened the mail</li>
      <li><span style={{ color: "red" }}>Bounce</span>: Mail could not be delivered</li>
      <li><span style={{ color: "red" }}>Hard bounce</span>: Mail could not be delivered because permanent issues (for example wrong or non existing email provider/address)</li>
      <li><span style={{ color: "red" }}>Soft bounce</span>: Mail oculd not be delivered because a temporary issue (for example full inbox)</li>
      <li><span style={{ color: "red" }}>Blocked</span>: Receiver have not received email, email is blocked</li>
      <li><span style={{ color: "red" }}>Junk mail</span>: Mail was reported as junk by receiver/provider.</li>
      <li><span style={{ color: "red" }}>Unsubscribed</span>: Receiver unsubscibed.</li>
      <li><span style={{ color: "red" }}>Blocked</span>: Receiver have not received email, e-mail is blocked</li>
      <li><span style={{ color: "red" }}>Invalid</span>: Receiver have not received the email, email is invalid.</li>
      <li><span style={{ color: "red" }}>Stopped</span>: Mail is in standby and can be received later (for example full inbox)</li>
      <li><span style={{ color: "red" }}>Error occured</span>: Receiver have not received mail, a problem occured during send.</li>
    </ul></>

  return <LayoutLeftMenu title={GetText("EmailLogs_EmailLogsTitle")} pageHelpContent={getCurrentLanguage() == 'se' ? pageHelpContent_se : pageHelpContent_en} menu={<LeftMenuCommunicate selected={"emailLogs"} />}><Space>
  </Space>
    <Paragraph>Sök nedan för att se historik och uppföljning på de mail som har skickats.</Paragraph>

    <Form form={form} colon={false} labelAlign={"left"} layout='vertical' >
      <Col span={24}>
        <BookyInput label={GetText("EmailLogs_Email")} name="email" placeholder={GetText("EmailLogs_Email")} />
        <OneFrontDatePicker label={GetText("EmailLogs_StartDate")} name="startDate" placeholder={GetText("EmailLogs_StartDate")} />
        <OneFrontDatePicker label={GetText("EmailLogs_EndDate")} name="endDate" placeholder={GetText("EmailLogs_EndDate")} />
        <BookyCheckbox alignLabelRight={true} label={GetText("EmailLogs_ShowOnlyError")} name="onlyError" />

        <Form.Item>
          <BookyButton
            type="primary"
            fullWidth={false}
            text={GetText("EmailLogs_Search")}
            onClick={searchEmailFollowUp}
          >
          </BookyButton>
        </Form.Item>

        {!isLoading ? <AdminEmailLogsTable logs={logs} isLoading={isLoading} /> : <Spin></Spin>}

      </Col>
    </Form>
  </LayoutLeftMenu>
};

export default AdminEmailLogs;