import React, { useState } from 'react';
import { Button, Col, Form, List, Modal, message } from 'antd';
import { BookyInput, InputType } from '../Forms/BookyInput';
import { DollarCircleOutlined } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';
import { WebApiModelsPayment } from '../../Repository/eventbookyapi';
import { MemberService } from '../../Services/MemberService';
import { BookyButton } from '../Buttons/BookyButton';
import { BookySelectDropDown } from '../Forms/BookySelectDropDown';
import { getLastThreeYearAsOptions } from '../../Helpers/PaymentYearOptions';
import { formatDateString, formatToShortFormat, getDateStringFromDate, getPreviousYear } from '../../Helpers/DateHelpers';
import { OneFrontDatePicker } from '../Forms/BookyDatePicker';
import moment from 'moment';

export const AddPayment = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const GetText = TextService.GetText;
    const [payments, setPayments] = useState([] as WebApiModelsPayment[]);

    const onCancel = () => {
        form.setFieldValue("groupName", "");
        setOpen(false);
        props.loadMembers();
    };

    const onCreate = async () => {

        try {
            await MemberService.SaveOrUpdatePayment({
                memberId: props.member.memberId,
                year: form.getFieldValue("year").toString(),
                paymentDate: new Date(form.getFieldValue("paymentDate").format('YYYY-MM-DD')),
                amount: form.getFieldValue("amount")
            });

            form.setFieldValue("text", "");
            loadPayments(false);
        } catch (error) {
            message.warning(GetText("EditMember_ErrorSaving"));
            return;
        }
    }

    const loadPayments = (isFirstLoading: boolean) => {

        if (isFirstLoading) {
            form.setFieldValue("year", getPreviousYear(0));
        }

        MemberService.GetPayments(props.member.memberId).then(result => {
            setPayments(result);

            result.forEach((element: WebApiModelsPayment) => {
                if (element.year == form.getFieldValue("year")) {
                    form.setFieldValue("amount", element.amount);
                    form.setFieldValue("paymentDate", moment(element.paymentDate));
                }
            });
        });
    }

    const deletePayment = (payment: WebApiModelsPayment) => {
        MemberService.DeletePayment(payment.id!).then(result => {
            loadPayments(false);
        })
    }

    const onYearChanged = (selectedYear: string) => {
        var yearFound = false;
        payments.forEach((element: WebApiModelsPayment) => {
            if (element.year == selectedYear) {
                yearFound = true;
                form.setFieldValue("amount", element.amount);
                form.setFieldValue("paymentDate", moment(element.paymentDate));
            }
        });

        if (!yearFound) {
            form.setFieldValue("amount", "");
            form.setFieldValue("paymentDate", null);
        }
    }

    const locale = {
        emptyText: GetText("AddPayment_NoPayments")
    }

    return (
        <>
            <Modal
                open={open}
                title={GetText("AddPayment_ModalTitle") + " - (" + props.member.uniqueId + ") " + props.member.firstName + " " + props.member.lastName}
                width={700}
                okText={GetText("EditMember_ModalClose")}
                onOk={onCancel}
                onCancel={onCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form form={form} colon={false} labelAlign={"left"} labelCol={{ span: 8, offset: 0 }} wrapperCol={{ span: 16 }} >
                    <Col span={17}>
                        <BookyInput type={InputType.number} label={GetText("AddPayment_NoteColumnPlaceHolder")} onClick={onCreate} name="amount" req placeholder={GetText("AddPayment_NoteColumnPlaceHolder")} />
                        <BookySelectDropDown label={GetText("AddPayment_YearColumn")} name="year" handleChange={onYearChanged} options={getLastThreeYearAsOptions()} />
                        <OneFrontDatePicker label={GetText("AddPayment_PaymentDateColumn")} name="paymentDate" placeholder={GetText("AddPayment_PaymentDatePlaceHolder")} />

                        <Form.Item label={" "}>
                            <BookyButton
                                type="primary"
                                fullWidth={false}
                                text={GetText("AddPayment_SaveNote")}
                                onClick={onCreate}
                            >
                            </BookyButton>
                        </Form.Item>


                    </Col>
                </Form>

                <List
                    locale={locale}
                    itemLayout="horizontal"
                    dataSource={payments}
                    renderItem={(item, index) => (
                        <List.Item extra={<Button size="small" onClick={() => deletePayment(item)}>{GetText("Common_Delete")}</Button>}>
                            <List.Item.Meta
                                title={<span>{item.year}</span>}
                                description={GetText("AddPayment_NoteColumnPlaceHolder") + ": " + item.amount + " | " + GetText("AddPayment_PaymentDateColumn") + ": " + formatToShortFormat(item.paymentDate)}
                            />
                        </List.Item>
                    )}
                />
            </Modal>


            <DollarCircleOutlined className={props.member.isPaidCurrentYear ? "" : "not-payed"}
                style={{ fontSize: "20px" }}
                type="primary"
                onClick={() => {
                    setOpen(true);
                    loadPayments(true);
                }}
            >
            </DollarCircleOutlined>
        </>
    );
};

export default AddPayment;