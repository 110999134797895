import { useEffect, useState } from 'react';
import { message, Col } from 'antd';
import { ClockCircleOutlined, DownloadOutlined, NotificationOutlined, TeamOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { EventService } from '../../Services/EventService';
import 'react-multi-email/dist/style.css';
import { AuthProvider } from '../../Providers/authProvider';
import { InviteService } from '../../Services/InviteService';
import { GroupService } from '../../Services/GroupService';
import { BookyTitleWithIcon } from '../../Components/Forms/TitleWithIcon';
import { EventDetails } from '../../Components/EventDetails/EventDetails';
import { ImageService } from '../../Services/ImageService';
import { BlobDto } from '../../Repository/eventbookyapi';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { ReplyTable } from '../../Components/EventDetails/ReplyTable';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import LeftMenuEventDetails from './LeftMenuEventDetails';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import { ReminderForm } from '../../Components/EventDetails/ReminderForm';
import { InviteList } from '../../Components/EventDetails/InviteList';

const MyEventDetails = () => {
  const [event, setEvent] = useState([] as any);
  const [messageApi, contextHolder] = message.useMessage();
  const [emails, setEmails] = useState<string[]>([]);
  const [invites, setInvites] = useState([] as any);
  const [invitesNoReply, setInvitesNoReply] = useState<string[]>([]);
  const [groups, setGroups] = useState([] as any[]);
  const [isModalOpen, setIsModalOpen] = useState(false as boolean);
  const [imageSelect, setImageSelect] = useState("" as string);
  const [images, setImages] = useState<BlobDto[]>([]);
  const [user, setUser] = useState({} as AuthenticatedUser | null);
  const GetText = TextService.GetText;

  let params = useParams();

  const loadInvites = (eventUniqueId: string) => {
    InviteService.GetInvites(eventUniqueId).then(inviteResult => {
      setInvites(inviteResult.invites);
      setEmails([]);

      let noReplies: string[] = [];
      inviteResult.invites?.forEach((invite: any) => {
        if (invite.replyId == 0) {
          noReplies.push(invite.email);
        }
      });

      setInvitesNoReply(noReplies);

    });
  }

  const loadEvent = (id: string) => {
    EventService.GetEventDetails(id).then(result => {
      setEvent(result);
      loadInvites(result.eventUniqueId ? result.eventUniqueId : "");
    });
  };

  const saveEventBannerImage = async (uri?: string) => {
    console.log(imageSelect.split("bloburl=")[1]);
    const result = await EventService.UpdateBannerImage({
      webApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto: {
        eventId: event.eventId,
        bannerImagePath: imageSelect ? imageSelect.split("bloburl=")[1] : uri,
        isCustomBannerImage: false
      }
    });

    setImageSelect("");
    message.success(GetText("MyEventDetails_Saved"));
    loadEvent(event.eventUniqueId);
  }

  const downloadExcel = async () => {
    if (user?.subscriptionLevel === SubscriptionLevel.Free) {
      message.warning(GetText("MyEventDetails_SorryOnlyPremium"));
      return;
    };
    const data = await EventService.GenerateExcelFromEvent({ eventId: event.eventId });
    const url = window.URL.createObjectURL(await data.raw.blob());
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `EventReport_${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }


  const deleteEventBannerImage = async () => {
    const result = await EventService.UpdateBannerImage({
      webApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto: {
        eventId: event.eventId,
        bannerImagePath: "",
        isCustomBannerImage: false
      }
    });

    setImageSelect("");
    message.success(GetText("MyEventDetails_HasBeenRemoved"));
    loadEvent(event.eventUniqueId);
  }

  const onClosePoll = async () => {
    loadEvent(event.eventUniqueId);
  }

  const onOpenPoll = async () => {
    loadEvent(event.eventUniqueId);
  }

  const onNotificationUpdate = async () => {
    loadEvent(event.eventUniqueId);
  }

  const onReplyDelete = async () => {
    loadEvent(event.eventUniqueId);
  }

  const loadImages = async () => {
    ImageService.GetBannerImages().then(result => {
      setImages(result);
    });
  }

  useEffect(() => {

    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    AuthProvider().ValidateUserLogin();
    if (params.id != null) {
      loadEvent(params.id);

      GroupService.GetGroups().then(result => {
        setGroups(result);
      });

      loadImages();
    }
  }, [params])


  return <LayoutLeftMenu hasTitleTop={true} titleTop={event.eventName} fullpageMain={true} title={GetText("SendMail_SendMailTitle")} menu={<LeftMenuEventDetails selected={"myevents"} />}>
    <Col span={16}>
      {contextHolder}
      <Col className='bookyBox' style={{ marginLeft: "20px" }}>
        <EventDetails event={event} onOpenPoll={onOpenPoll} onNotificationUpdate={onNotificationUpdate} />
      </Col>

      <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }}>
        <BookyTitleWithIcon text={GetText("MyEventDetails_Replies")} rightContent={<span style={{ cursor: "pointer" }} onClick={downloadExcel}>{GetText("MyEventDetails_Download")} <DownloadOutlined alt="Download Excel" /></span>}><TeamOutlined /></BookyTitleWithIcon>

        {event?.timeAnswers?.length == 0 ? <i>{GetText("MyEventDetails_NoRepliesFromGuests")}</i> : <ReplyTable onReplyDelete={onReplyDelete} user={user} onClosePoll={onClosePoll} event={event} />}
      </Col>

      <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }}>
        <BookyTitleWithIcon text={GetText("MyEventDetails_SendInvites")}><NotificationOutlined /></BookyTitleWithIcon>
        <InviteList loadEvent={loadEvent} event={event} invites={invites}></InviteList>
      </Col>

      <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }}>
        <BookyTitleWithIcon text={GetText("MyEventDetails_Reminders")}><ClockCircleOutlined /></BookyTitleWithIcon>
        <ReminderForm invitesNoReply={invitesNoReply} event={event} invites={invites} ></ReminderForm>
      </Col>
    </Col>


  </LayoutLeftMenu>
};

export default MyEventDetails;