import { useEffect, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { UserOutlined, TeamOutlined, UserAddOutlined, BarChartOutlined, NotificationOutlined, MailOutlined, EditOutlined, FormatPainterOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';

const LeftMenuEventDetails = (props: any) => {
    const [current, setCurrent] = useState('1');
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const GetText = TextService.GetText;
    const navigation = useNavigate();
    const param = useParams();
    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        });

        setCurrent(props.selected);
    }, []);

    type MenuItem = Required<MenuProps>['items'][number];

    const onClick: MenuProps['onClick'] = (e: any) => {
        if (e.key == "myevents") {
            navigation("/" + getCurrentLanguage() + "/myevents/" + param.id);
        }

        if (e.key == "myeventparticipants") {
            navigation("/" + getCurrentLanguage() + "/myevents/" + param.id + "/participants");
        }

        if (e.key == "editevent") {
            navigation("/" + getCurrentLanguage() + "/myevents/" + param.id + "/edit");
        }

        if (e.key == "myeventreminders") {
            navigation("/" + getCurrentLanguage() + "/myevents/" + param.id + "/reminders");
        }

        if (e.key == "brandevent") {
            navigation("/" + getCurrentLanguage() + "/myevents/" + param.id + "/branding");
        }

        if (e.key == "sendInviteGroup") {
            navigation("/" + getCurrentLanguage() + "/myevents/" + param.id + "/sendinvitegroup");
        }
        if (e.key == "sendInviteMember") {
            navigation("/" + getCurrentLanguage() + "/myevents/" + param.id + "/sendinvitemember");
        }

        if (e.key == "sendInviteExternal") {
            navigation("/" + getCurrentLanguage() + "/myevents/" + param.id + "/sendinviteexternal");
        }

    };

    function getItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }
    const sendMailItems: MenuItem[] = [
        getItem(GetText("SendInvite_SendInviteGroupTitle"), 'sendInviteGroup', <TeamOutlined />),
        getItem(GetText("SendInvite_SendInviteMemberTitle"), 'sendInviteMember', <UserOutlined />),
        getItem(GetText("SendInvite_SendInviteExternalTitle"), 'sendInviteExternal', <UserAddOutlined />),
    ];

    const items: MenuItem[] = [
        getItem("Status & Svar", 'myevents', <TeamOutlined />),
        getItem("Ändra", 'editevent', <EditOutlined />),
        getItem(GetText("MyEventDetails_BrandTitle"), 'brandevent', <FormatPainterOutlined />),
        // getItem(GetText("MyEventDetails_SendInvites"), 'myeventparticipants', <NotificationOutlined />),
        // getItem(GetText("MyEventDetails_Reminders"), 'myeventreminders', <ClockCircleOutlined />),
        getItem("Skicka inbjudningar", 'myeventparticipants', <MailOutlined />, sendMailItems, "group"),
    ];

    return <Menu
        theme="light"
        onClick={onClick}
        defaultOpenKeys={['myeventparticipants']}
        selectedKeys={[current]}
        inlineCollapsed={false}
        mode="inline"
        items={items}
        disabledOverflow={true}
    />
};

export default LeftMenuEventDetails;