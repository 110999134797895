
import { Alert, Button, Divider, Form, message, Modal, Select, SelectProps, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { AuthProvider } from "../../Providers/authProvider";
import { AuthenticatedUser } from "../../Types/AuthenticatedUser";
import { BookyInput } from "../Forms/BookyInput";
import { GroupService } from "../../Services/GroupService";
import { TextService } from "../../Services/TextService";
import { MemberService } from "../../Services/MemberService";
import { WebApiCompanyDtoCompanyDto, WebApiMemberDtoMemberDto, WebApiModelsEmailTemplate, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from "../../Repository/eventbookyapi";
import FileSelect from "../Upload/UploadFile";
import { UploadChangeParam } from "antd/lib/upload";
import { BookyButton } from "../Buttons/BookyButton";
import { MemberAutocomplete } from "../AutoComplete/MemberAutocomplete";
import { MemberTable } from "../Table/MemberTable";
import { MemberPaymentStatus } from "../../Types/MemberPaymentStatus";
import { EmailFormType } from "../../Types/EmailFormType";
import { OneFrontHtmlEditor } from "../Forms/OneFrontHtmlEditor";
import { CompanyService } from "../../Services/CompanyService";
import { BookySelectDropDown } from "../Forms/BookySelectDropDown";
import { ContactAutoComplete } from "../AutoComplete/ContactAutocomplete";
import { BookyCheckbox } from "../Forms/BookyCheckbox";
import { EmailNotificationPreference } from "../../Types/EmailNotificationPreference";
import { InviteService } from "../../Services/InviteService";

export const SendInviteForm = (props: any) => {
    const [form] = useForm();
    const [initialHtml, setInitialHtml] = useState("" as any);
    const [html, setHtml] = useState("" as any);
    const [externalEmails, setExternalEmails] = useState([] as any[]);

    const [company, setCompany] = useState({} as WebApiCompanyDtoCompanyDto);

    const [loading, setLoading] = useState(false as boolean);
    const [showConfirm, setShowConfirm] = useState(false as boolean);
    const [selectedGroup, setSelectedGroup] = useState([] as number[]);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(undefined as MemberPaymentStatus | undefined);

    const [fileResult, setFileList] = useState<UploadChangeParam>();
    const [isAllowedToSendGroupMails, setIsAllowedToSendGroupMails] = useState(true as boolean);
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const [selectedMembers, setSelectedMembers] = useState([] as WebApiMemberDtoMemberDto[]);

    const GetText = TextService.GetText;

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);

            if (props.groups != null && props.groups.length > 0) {
                setSelectedGroup(props.groups[0]);
                form.setFieldValue("group", props.groups[0].id)
            }
        });

        CompanyService.GetCompany().then(company => {
            setCompany(company);
        })

        GroupService.IsAllowedToSendGroupMails().then(result => {
            setIsAllowedToSendGroupMails(result.toString() === true.toString());
        });

        if (props.subject != null) {
            form.setFieldValue("subject", props.subject);
        }
    }, [props]);

    const onReminderFinish = async (formValues: any) => {
        setShowConfirm(true);
    };

    const onFileSelectChange = (files: any) => {
        setFileList(files);
    }

    const onFilterMembers = async () => {
        let filteredMembers: WebApiMemberDtoMemberDto[] = [];

        if (!selectedGroup.length) {
            message.warning(GetText("EmailForm_SelectOneGroup"));
        }

        filteredMembers = await MemberService.GetFilteredMembers({
            status: selectedPaymentStatus,
            emailNotificationPreference: form.getFieldValue("emailNotificationPreference") == 0 || !form.getFieldValue("emailNotificationPreference") ? EmailNotificationPreference.Unspecified : EmailNotificationPreference.NoInformationEmails,
            isAllMembers: selectedGroup.length == 0 || selectedGroup.some(p => p == -1) ? true : false,
            groupsIds: selectedGroup
        });

        setSelectedMembers(filteredMembers);

        if (filteredMembers.length == 0) {
            message.warning("No memebers found.")
        }
    }


    const onSendConfirmOK = async () => {
        setLoading(true);

        if (selectedMembers.length == 0) {
            message.error("Select at least one member");
            return;
        }

        var inviteEmails: string[] = selectedMembers.map(m => m.email!);

        try {
            await InviteService.CreateInvites({
                subject: form.getFieldValue("subject"),
                emails: inviteEmails,
                files: getFileFromUploadControl(),
                message: html,
                eventId: props.event.eventId
            });
            message.success(GetText("SendInviteForm_MessageSuccess"));
        } catch {
            message.error(GetText("EmailForm_EmailsSentFailed"))
        }

        setLoading(false);
        form.setFieldValue("message", "");
        form.setFieldValue("messageFromTemplate", "");
        form.setFieldValue("subject", "");
        setShowConfirm(false);
    };

    const getFileFromUploadControl = (): Blob[] => {
        var files: Blob[] = [];

        fileResult?.fileList.forEach((file: any) => {
            if (file.originFileObj) {
                files.push(file.originFileObj as Blob);
            }
            else {
                files.push(file as Blob);
            }
        });

        return files;
    }

    const onSendConfirmOKExternal = async () => {
        setLoading(true);

        if (externalEmails.length == 0) {
            message.error("Select at least one email");
            return;
        }

        try {
            await InviteService.CreateInvites({
                emails: externalEmails,
                subject: form.getFieldValue("subject"),
                files: getFileFromUploadControl(),
                message: html,
                eventId: props.event.eventId
            });

            message.success(GetText("EmailForm_EmailsSent"));
        } catch {
            message.error(GetText("EmailForm_EmailsSentFailed"))
        }

        setLoading(false);
        form.setFieldValue("message", "");
        form.setFieldValue("messageFromTemplate", "");
        form.setFieldValue("subject", "");
        setShowConfirm(false);
    }

    const onAutoCompleteSelect = (member: WebApiMemberDtoMemberDto) => {
        let newList: WebApiMemberDtoMemberDto[] = selectedMembers.map(p => p);
        if (!selectedMembers.some((e: WebApiMemberDtoMemberDto) => e.email === member.email)) {
            newList.push(member);
        }
        setSelectedMembers(newList);
    }

    const removeMember = (memberToRemove: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto) => {
        let newList = selectedMembers.filter(p => p.email != memberToRemove.email);
        setSelectedMembers(newList);
    };


    const groupOptions = () => {
        const options: SelectProps['options'] = [];

        props.groups.forEach((element: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto) => {
            options.push({
                label: element.name,
                value: element.id,
            });
        });

        return options;
    }

    const emailTemplatesOptions = () => {
        const options: SelectProps['options'] = [];

        props.emailTemplates.forEach((element: WebApiModelsEmailTemplate) => {
            options.push({
                label: element.templateName,
                value: element.id,
            });
        });

        return options;
    }

    const handleGroupChange = async (value: number[]) => {
        setSelectedGroup(value);
    };

    const handleTemplateChange = async (value: number) => {
        let emailTemplate: WebApiModelsEmailTemplate[] = props.emailTemplates.filter((p: WebApiModelsEmailTemplate) => p.id === value);
        if (emailTemplate && emailTemplate.length > 0) {
            setInitialHtml(emailTemplate[0]?.body);
            setHtml(emailTemplate[0]?.body);
            form.setFieldValue("message", emailTemplate[0]?.body);
            form.setFieldValue("messageFromTemplate", emailTemplate[0]?.body);
            form.setFieldValue("subject", emailTemplate[0]?.subject);
        }
        else {
            setInitialHtml(undefined);
            form.setFieldValue("subject", "");
        }
    };

    const onSendConfirmCancel = async () => {
        setShowConfirm(false);
    };

    const onSelectPaymentChanged = async (value: MemberPaymentStatus) => {
        setSelectedPaymentStatus(value);
    };

    const onHtmlChange = (html: string) => {
        form.setFieldValue("message", html);
        setHtml(html);
    }

    const onEmailsUpdated = async (onEmailsUpdated: any) => {
        setExternalEmails(onEmailsUpdated => ([
            ...onEmailsUpdated,
        ]));
    }

    return <>
        <Modal
            title={GetText("EmailForm_ConfirmText")}
            open={showConfirm}
            onOk={props.type === EmailFormType.EmailFormByExternal ? onSendConfirmOKExternal : onSendConfirmOK}
            confirmLoading={loading}
            onCancel={onSendConfirmCancel}
        >
            {props.type === EmailFormType.EmailFormByExternal ?
                (GetText("EmailForm_ConfirmText1") + externalEmails.length + GetText("EmailForm_ConfirmText2")) :
                (GetText("EmailForm_ConfirmText1") + selectedMembers.length + GetText("EmailForm_ConfirmText2"))}
        </Modal>
        {user?.hasPaidSubscription ?
            <>
                {isAllowedToSendGroupMails === true ? <Form
                    form={form}
                    layout="vertical"
                    onFinish={onReminderFinish}
                    autoComplete="off"
                >
                    <Form.Item
                    >
                        {company && company.email && company.name ?
                            <>{GetText("EmailForm_From")} {company.name} {"<" + company.email + ">"}</> :
                            <>{GetText("EmailForm_From")} {user.firstName} {user.lastName} {"<" + user.email + ">"}</>}
                    </Form.Item>

                    <h3>{GetText("EmailForm_Step1")}</h3>
                    <Divider style={{ marginTop: "5px" }} />
                    <span style={{ color: "red" }}>*</span> {GetText("EmailForm_ChooseReceiver")}
                    {props.type === EmailFormType.EmailFormByGroup && props.groups.length > 0 ? <><br />
                        <Select placeholder={GetText("EmailForm_ChooseCategories")} onChange={handleGroupChange} style={{ width: '400px', marginBottom: "10px" }} mode="multiple" options={groupOptions()}></Select>
                        <br /><BookyCheckbox alignLabelRight={true} label={"Skicka även till medlemmar som inte önskar e-post"} name="emailNotificationPreference"></BookyCheckbox>
                    </> : ""}

                    {props.type === EmailFormType.EmailFormByMember ? <MemberAutocomplete showList={false} onAutoCompleteSelect={onAutoCompleteSelect} listHeader={GetText("ReminderForm_ReminderListHeader")} members={props.memberResult.members} /> : ""}
                    {props.type === EmailFormType.EmailFormByGroup ? <><br /><BookyButton style={{ marginTop: "10px" }} text={GetText("EmailForm_CreateSendList")} onClick={onFilterMembers}></BookyButton></> : ""}
                    {props.type === EmailFormType.EmailFormByExternal ? <><br /> <ContactAutoComplete showList listHeader={GetText("EmailForm_ExternalMailHeader")} emails={externalEmails} onEmailsUpdated={onEmailsUpdated} /></> : ""}

                    {selectedMembers.length === 0 ? "" : <MemberTable isMail={true} members={selectedMembers} style={{ minWidth: '400px', marginTop: "10px" }} removeMember={removeMember} />}
                    {selectedMembers.length > 0 ? <><br /><i>{selectedMembers.length} {GetText("EmailForm_ChosenReceivers")}</i></> : ""}


                    <h3 style={{ marginTop: "20px" }}>{GetText("EmailForm_Step2")}</h3>
                    <Divider style={{ margin: "5px" }} />
                    <BookySelectDropDown allowClear={true} placeholder={GetText("EmailForm_LoadFromEmailTemplate")} handleChange={handleTemplateChange} style={{ width: '400px', marginTop: "20px" }} options={emailTemplatesOptions()}></BookySelectDropDown>
                    <BookyInput label={GetText("EmailForm_Subject")} name="subject" style={{ width: '400px', marginTop: "10px" }} requiredMessage={GetText("EmailForm_SubjectRequired")} ></BookyInput>

                    {/* Workaround to get editor to refresh value */}
                    {initialHtml ? <OneFrontHtmlEditor hideVariablesMenu={props.type === EmailFormType.EmailFormByExternal} initialHtml={initialHtml} onHtmlChange={onHtmlChange} label={GetText("EmailForm_Message")} name="messageFromTemplate" requiredMessage={GetText("EmailForm_MessageRequired")} /> :
                        <OneFrontHtmlEditor hideVariablesMenu={props.type === EmailFormType.EmailFormByExternal} initialHtml={initialHtml} onHtmlChange={onHtmlChange} label={GetText("EmailForm_Message")} name="message" requiredMessage={GetText("EmailForm_MessageRequired")} />}

                    <Form.Item
                        name="upload"
                        label={GetText("EmailForm_AttachFile")}
                    >
                        <FileSelect onFileSelectChange={onFileSelectChange} />
                    </Form.Item>

                    <Form.Item>
                        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                            <Button disabled={(selectedMembers.length == 0 && externalEmails.length == 0) || html.length < 1 || form.getFieldValue("subject") == ""} type="primary" htmlType="submit">
                                {GetText("SendInviteForm_SendButtonText")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form> : <Alert
                    message={GetText("EmailForm_AlertSuspendedMessage")}
                    description={GetText("EmailForm_AlertSuspendedDescription")}
                    type="warning"
                />}
            </> : <span><Tag color="#1890ff">Premium</Tag><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a>{GetText("EmailForm_PremiumSendingMessageToGroups")}</span>
        }

    </>
}



























// import Icon, { ExclamationCircleFilled, UsergroupAddOutlined } from "@ant-design/icons";
// import { AutoComplete, Button, Checkbox, Dropdown, Form, Input, List, Menu, MenuProps, message, Modal, Space, Tag } from "antd";
// import { CheckboxChangeEvent } from "antd/lib/checkbox";
// import { useForm } from "antd/lib/form/Form";
// import TextArea from "antd/lib/input/TextArea";
// import { ItemType } from "antd/lib/menu/hooks/useItems";
// import Paragraph from "antd/lib/typography/Paragraph";
// import { useEffect, useState } from "react";
// import { AuthProvider } from "../../Providers/authProvider";
// import { GoogleAuthenticationScope, GoogleTokenProvider } from "../../Providers/GoogleTokenProvider";
// import { GoogleService } from "../../Services/GoogleService";
// import { GroupService } from "../../Services/GroupService";
// import { InviteService } from "../../Services/InviteService";
// import { UserService } from "../../Services/UserService";
// import { AuthenticatedUser } from "../../Types/AuthenticatedUser";
// import { members } from "../../Types/RequestResponse/createGroupRequest";
// import { SubscriptionLevel } from "../../Types/SubscriptionLevel";
// import { GoogleIcon } from "../Buttons/GoogleButton";
// import { TextService } from "../../Services/TextService";
// import { OneFrontHtmlEditor } from "../Forms/OneFrontHtmlEditor";


// export const SendInviteForm = (props: any) => {
//     const [googleToken, setGoogleToken] = useState("" as string);
//     const [googleContacts, setGoogleContacts] = useState([] as any[]);
//     const [options, setOptions] = useState<{ value: string }[]>([]);

//     const [value, setValue] = useState('');
//     const [groupOptions, setGroupOptions] = useState([] as ItemType[]);
//     const [focused, setFocused] = useState(false as boolean);
//     const [inviteEmails, setinviteEmails] = useState([] as any[]);
//     const [selectedInvite, setSelectedInvite] = useState("" as any);
//     const [confirmLoading, setConfirmLoading] = useState(false);

//     const [checkedSaveAsGroup, setCheckedSaveAsGroup] = useState(false as boolean);
//     const [groupName, setGroupName] = useState("" as string);
//     const [inviteText, setInviteText] = useState("" as any);
//     const [user, setUser] = useState({} as AuthenticatedUser | null);
//     const GetText = TextService.GetText;

//     useEffect(() => {
//         AuthProvider().GetLoggedInUser().then(user => {
//             setUser(user);
//             setInviteText(GetText("SendInviteForm_SendDefaultText0") + user?.firstName + " " + user?.lastName + " " + GetText("SendInviteForm_SendDefaultText1") + props.event.eventName + GetText("SendInviteForm_SendDefaultText2"));
//         });
//     }, [props.event])

//     const AuthenticateGoogle = () => {
//         let googleArray: any[] = [];

//         GoogleTokenProvider.GetToken(async (response: any) => {
//             setGoogleToken(response.access_token);
//             const result = await GoogleService.GetGoogleContacts(response.access_token, "");
//             result.otherContacts.forEach((resource: any) => {
//                 resource.emailAddresses.forEach((email: any) => {
//                     googleArray.push({ "label": email.value, "value": email.value });
//                 });
//             });

//             setGoogleContacts(googleArray);
//         }, GoogleAuthenticationScope.ContactsReadOnly);
//     }


//     const onFinish = async (formValues: any) => {
//         if (user?.subscriptionLevel == SubscriptionLevel.Free && (inviteEmails.length + props.invites.length) > 25) {
//             message.warning(GetText("SendInviteForm_ErrorMaxParticipants"));
//             return;
//         }

//         if (inviteEmails.length == 0) {
//             message.warning(GetText("SendInviteForm_ErrorZeroEmails"));
//             return;
//         }

//         if (checkedSaveAsGroup && groupName.length == 0) {
//             message.warning(GetText("SendInviteForm_ErrorEmptyGoupName"));
//             return;
//         }
//         const { confirm } = Modal;
//         confirm({
//             title: GetText("SendInviteForm_ConfirmText"),
//             icon: <ExclamationCircleFilled />,
//             content: GetText("SendInviteForm_ConfirmDescription1") + inviteEmails.length + GetText("SendInviteForm_ConfirmDescription2"),
//             async onOk() {

//                 // Save group
//                 let groupMembers: members[] = [];

//                 inviteEmails.forEach(invite => {
//                     groupMembers.push({ email: invite, name: invite });
//                 });

//                 let createGroupRequest = {
//                     name: groupName,
//                     members: groupMembers
//                 }

//                 if (checkedSaveAsGroup) {

//                     try {
//                         await GroupService.CreateGroup(createGroupRequest);
//                     } catch (error) {
//                         message.warning(GetText("SendInviteForm_ConfirmDescription1"));
//                         return;
//                     }
//                 }

//                 const result = await InviteService.CreateInvites({
//                     webApiServicesDataTransferObjectsInviteServiceCreateInviteDto: {
//                         emails: inviteEmails,
//                         message: inviteText,
//                         eventId: props.event.eventId
//                     }
//                 });

//                 setGroupName('');
//                 setinviteEmails([]);
//                 props.loadInvites(props.event.eventUniqueId);
//                 message.success(GetText("SendInviteForm_MessageSuccess"));
//             },
//             onCancel() {
//                 console.log('Cancel');
//             },
//         });
//     };

//     const onGroupSelect: MenuProps['onClick'] = ({ key }) => {
//         let members: string[] = [];

//         props.groups.forEach((group: any) => {
//             if (group.id == key) {
//                 group.members.forEach((member: any) => {
//                     members.push(member.email);
//                 });
//             }
//         });

//         addToSavedEmails(members.join(","));
//     }

//     const addToSavedEmails = (value: string) => {
//         let newList = inviteEmails;
//         if (value.indexOf(",") != -1) {
//             const emails = value.split(",");
//             emails.forEach(newMail => {
//                 if (newList.indexOf(newMail) == -1) {
//                     newList.push(newMail);
//                 }
//             })
//         }
//         else {
//             if (validateEmail(value)) {
//                 if (newList.indexOf(value) == -1) {
//                     newList.push(value);
//                 }
//             }
//         }
//         setValue('');
//         setinviteEmails(newList => ([
//             ...newList,
//         ]));
//     }

//     const validateEmail = (email: string) => {
//         const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
//         const result: boolean = expression.test(email); // true

//         return result;
//     }

//     const removeFromNewInviteList = (email: string) => {
//         const index = inviteEmails.indexOf(email, 0);
//         if (index > -1) {
//             inviteEmails.splice(index, 1);
//         }

//         setinviteEmails(inviteEmails => ([
//             ...inviteEmails,
//         ]));

//     }

//     const onSearch = (searchText: string) => {
//         setValue(searchText);
//         const filteredSuggestions = googleContacts.filter((itemData: any) => {
//             return itemData.label.startsWith(searchText);
//         })

//         setOptions(
//             !searchText ? [] : filteredSuggestions,
//         );
//     };


//     const groupMenu = () => {
//         return (<Menu onClick={onGroupSelect}>
//             {props.groups.map((group: any) => (
//                 <Menu.Item key={group.id}>{group.name}</Menu.Item>
//             ))}
//         </Menu>);
//     }

//     const [form] = useForm();
//     return <>
//         <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
//             <Paragraph>{GetText("SendInviteForm_InviteDescription")}</Paragraph>
//         </Space>
//         <Form
//             form={form}
//             layout="vertical"
//         >
//             <div style={{ marginBottom: "20px" }}>
//                 {props.groups.length > 0 ? <Dropdown overlay={groupMenu}>
//                     <Button style={{ marginLeft: "10px" }}>
//                         <Space>
//                             {GetText("SendInviteForm_GetContactFromGroup")}<UsergroupAddOutlined />
//                         </Space>
//                     </Button>
//                 </Dropdown> : ""}
//             </div>
//             <Form.Item
//                 name="Email addresses"
//                 label={GetText("SendInviteForm_EmailAdressesForm")}
//             >
//                 <div>

//                     <AutoComplete
//                         options={options}
//                         style={{ width: 500 }}
//                         onSelect={(data: any) => { addToSavedEmails(data); }}
//                         onSearch={onSearch}
//                         onKeyDown={(data: any) => { data.code == "Enter" ? addToSavedEmails(value) : data.code = "1" }}
//                         placeholder={GetText("SendInviteForm_EmailAdressesPlaceHolder")}
//                         value={value}>
//                     </AutoComplete> <Button type={"primary"} onClick={() => { addToSavedEmails(value) }}>Add e-mail</Button><br></br>
//                     <div className="descriptionText">{GetText("SendInviteForm_AddmultipleDescription")} <br />steve.smith@gmail.com,jonas.anderson@outlook.com</div>

//                     {inviteEmails.length > 0 ?
//                         <div style={{ marginTop: "10px" }}><b>Invites ({inviteEmails.length})</b><br></br>
//                             <List
//                                 pagination={{ pageSize: 10 }}
//                                 style={{ width: "500px" }}
//                                 dataSource={inviteEmails}
//                                 renderItem={(item) => (
//                                     <List.Item extra={<Button size="small" onClick={() => removeFromNewInviteList(item)}>Delete</Button>}>
//                                         {item}
//                                     </List.Item>
//                                 )}></List>
//                         </div> : ""}
//                     <br></br><br></br>
//                     {user?.subscriptionLevel == SubscriptionLevel.Free && props.groups.length == 1 ? <span className='pro-link' style={{ justifyContent: 'left', display: 'flex' }}><Tag color="#1890ff">{GetText("Common_Premium")}</Tag><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a>{GetText("SendInviteForm_UpgradeMoreGroups")}</span> : ""}
//                     <Checkbox disabled={(user?.subscriptionLevel === SubscriptionLevel.Free && props.groups.length === 1)} onChange={((e: CheckboxChangeEvent) => { setCheckedSaveAsGroup(!checkedSaveAsGroup) })}>{GetText("SendInviteForm_SaveEmailsAsGroup")}</Checkbox>
//                 </div>
//             </Form.Item>
//             {checkedSaveAsGroup ? <Form.Item
//                 name="groupName"
//                 label={GetText("SendInviteForm_GroupNameForm")}
//                 rules={[
//                     {
//                         validator: (_, value) => {
//                             if (value != "") {
//                                 return Promise.resolve();
//                             } else {
//                                 return Promise.reject(GetText("SendInviteForm_GroupNameMandatory"));
//                             }
//                         }
//                     }
//                 ]}
//             >
//                 <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
//                     <Input style={{ width: "500px" }} onChange={(e: any) => { setGroupName(e.target.value); }} placeholder="Enter the name of the new group" />
//                 </Space>
//             </Form.Item> : ""}

//             <Form.Item
//                 name="Message"
//                 label={GetText("SendInviteForm_GroupNameMessage")}
//                 rules={[
//                     {
//                         validator: (_, value) => {
//                             if (value != "") {
//                                 return Promise.resolve();
//                             } else {
//                                 return Promise.reject(GetText("SendInviteForm_GroupMessageMandatory"));
//                             }
//                         }
//                     }
//                 ]}
//             >
//                 <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
//                     <OneFrontHtmlEditor initialHtml={inviteText} hideVariablesMenu={true} onHtmlChange={(e: any) => setInviteText(e)} name="body" />
//                 </Space>
//             </Form.Item>


//             <Form.Item>
//                 <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
//                     <Button type="primary" onClick={onFinish}>
//                         {GetText("SendInviteForm_SendButtonText")}
//                     </Button>
//                 </Space>
//             </Form.Item>
//         </Form>
//         {user?.subscriptionLevel === SubscriptionLevel.Free ? <span className='pro-link' style={{ justifyContent: 'left', display: 'flex' }}><Tag color="#1890ff">{GetText("Common_Premium")}</Tag><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a> {GetText("SendInviteForm_UpgradeToInviteMoreGuests")}</span> : ""}
//     </>
// }