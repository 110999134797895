import { Button, Col, Divider, Form, Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import { EventService } from '../../Services/EventService';
import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { formatDateRangeWithDateString, formatDateString, getTimeRangeString } from '../../Helpers/DateHelpers';
import { useSaveReply } from '../../Hooks/useSaveReply';
import { useNavigate } from "react-router-dom";
import { getTimeZones } from '@vvo/tzdb';
import { EventState } from './EventState';
import './Event.css'
import Page from '../../Components/Page/Page';
import BookyBox from '../../Components/Layout/BookyBox';
import NotFound from '../../Components/Page/NotFoundPage';
import ErrorPage from '../../Components/Page/ErrorPage';
import { ReplyService } from '../../Services/ReplyService';
import { BookyInput, InputType } from '../../Components/Forms/BookyInput';
import { ReplyTimeForm } from '../../Components/Forms/ReplyTimeForm';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';

const Event = () => {
    const [state, setState] = useState([] as any);
    const [event, setEvent] = useState([] as any);
    const [reply, setReply] = useState([] as any);
    const [eventState, setEventState] = useState(EventState.Open as EventState);
    let params = useParams();
    const { SaveReply } = useSaveReply();
    const navigation = useNavigate();
    const [form] = Form.useForm();
    const GetText = TextService.GetText;

    useEffect(() => {
        if (params.id != null) {
            EventService.GetEvent(params.id).then(result => {
                if (result.deadline != null && new Date() > new Date(result.deadline)) {
                    setEventState(EventState.DeadlinePassed);
                    return;
                }

                if (result.state === EventState.Closed) {
                    setEventState(EventState.Closed);
                    return;
                }

                setEvent(result);

                if (params.id != null && (params.inviteId != null || params.authCode != null)) {
                    ReplyService.GetReplyByInviteIdOrAuthCode(params.id, params.inviteId != null ? params.inviteId : "", params.authCode != null ? params.authCode : "").then(result => {
                        setReply(result);

                        form.setFieldsValue({
                            name: result.name,
                            email: result.email,
                            answer: result.message,
                        });

                        result.replyTime?.forEach((replyTime: any) => {
                            form.setFieldValue(replyTime.timeId, replyTime.customerAnswerType.toString());
                        });

                    });
                }

            }).catch((error) => {
                setEventState(EventState.NotFound);
            });
        }
    }, [params, form])

    const FormCreateClick = async (formValues: any) => {
        SaveReply(event.id, formValues, event.times, params.inviteId, reply != null ? reply.replyId : null);
        navigation("/" + getCurrentLanguage() + "/event/completed", {
            state: {
                name: event.name
            }
        });
    }

    let timeZoneInfo = ""
    getTimeZones().forEach(element => {
        if (element.name === event.timeZoneId) {
            timeZoneInfo = element.currentTimeFormat;
        }
    });

    if (eventState === EventState.NotFound) {

        return <NotFound message={GetText("Event_EventNotFound")} />
    }

    if (eventState === EventState.DeadlinePassed) {

        return <div>
            <ErrorPage message={GetText("Event_EventPassed")} />
        </div>
    }

    if (eventState === EventState.Closed
    ) {

        return <div>
            <ErrorPage message={GetText("Event_EventClosed")} />
        </div>
    }

    const getAnswerByTimeId = (timeId: string) => {
        return form.getFieldValue(timeId);
    };

    const onChangeAnswerComplete = (timeId: string, answer: string) => {
        form.setFieldValue(timeId, answer);
        setState(!state);
    }

    const isFullyBooked = (time: any, event: any) => {
        return time.acceptedCount >= event.maxParticipants;
    }

    return <Page>
        <BookyBox>
            <Form
                data-testid="create-form"
                name="basic"
                form={form}
                colon={false}
                labelCol={{
                    span: 7,
                }}
                onFinish={FormCreateClick}
                wrapperCol={{
                    span: 15,
                }}
                layout="horizontal"
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                {


                    event.bannerImagePath ? <div style={{ marginBottom: "20px" }}>
                        <div className='hero-image' style={{
                            backgroundImage: `url(${(process.env.REACT_APP_API_URL + "/Image/downloadImage?bloburl=" + event.bannerImagePath)}`
                        }} >
                        </div>
                        <div><Title style={{ margin: 0, color: event.bannerImageColorCode ?? event.bannerImageColorCode }}>{event.name}</Title></div>
                    </div> : <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                        <Title style={{ margin: 0 }}>{event.name}</Title>
                    </Space>}


                <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                    <Paragraph>{event.description}</Paragraph>
                </Space>
                <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                    <Paragraph>{event.location}</Paragraph>
                </Space>
                {event.deadline ? <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                    <Paragraph>{GetText("Event_EventLastDayReply")} <b>{formatDateString(event.deadline)}</b></Paragraph>
                </Space> : null}
                <Divider />

                <BookyInput label={GetText("Event_Name")} name="name" required={true} requiredMessage={GetText("Event_Name_Placeholder")} />
                <BookyInput label={GetText("Event_Email")} name="email" required={true} type={InputType.email} requiredMessage={GetText("Event_Email")} />
                <BookyInput label={GetText("Event_MesssageToOrganizer")} name="answer" required={true} message={GetText("Event_MesssageToOrganizer")} />

                {event.times?.map((time: any, i: any) =>

                    <Form.Item
                        label={formatDateRangeWithDateString(time.start, time.end, time.isFullDay, false)}
                        name={time.id}
                        key={time.id}
                        rules={[
                            {
                                required: !isFullyBooked(time, event),
                                message: GetText("Event_PlaseAnswerAvailabilityValidationMessage"),
                            },
                        ]}
                    >
                        <ReplyTimeForm event={event} time={time} onChangeAnswerComplete={onChangeAnswerComplete} answer={getAnswerByTimeId(time.id)} isFullyBooked={isFullyBooked(time, event)} />
                    </Form.Item>
                )}
                <Row>
                    <Col span={16} offset={6}>
                        <Space style={{ width: '100%', justifyContent: 'left' }}>
                            <Paragraph style={{ fontSize: "80%" }}> {GetText("Event_TimezoneInformation")} ({timeZoneInfo})</Paragraph>
                        </Space>
                    </Col>
                </Row>

                <Form.Item
                    label=" "
                    name="submit"
                    rules={[
                        {
                            required: false,
                            message: 'submitbutton',
                        },
                    ]}
                >
                    <Button data-testid="submit-button" style={{ float: 'left', backgroundColor: event.primaryColorCode ? event.primaryColorCode : "var(--color-primary)", color: event.secondaryColorCode ? event.secondaryColorCode : "white" }} size="middle" type="primary" htmlType="submit">
                        {reply?.email == null ? GetText("Event_SubmitAnswer") : GetText("Event_UpdateAnswer")}
                    </Button>
                </Form.Item>
            </Form>
        </BookyBox>
    </Page>
}

export default Event;