import { useState } from "react";
import { CalendarEvent, CalenderSource } from "../Types/CalendarEvent";
import { time } from '../Types/RequestResponse/createEventResponse';
import { EventService } from '../Services/EventService';
import addMinutes from "date-fns/addMinutes";
import { getTimeZones } from "@vvo/tzdb";
import { updateEventWithBookingsRequest } from "../Types/RequestResponse/updateEventRequest";
import { getUtcDate } from "../Helpers/DateHelpers";
import moment from "moment-timezone";
import { EventEditState } from "../Pages/Event/EventState";
import { TextService } from "../Services/TextService";
import { WebApiModelsEnumerationTypesDisplayType, WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto } from "../Repository/eventbookyapi";

export const useUpdateEvent = () => {
  const [events, setEvents] = useState([] as CalendarEvent[]);
  const [step, setStep] = useState(30 as number);
  const [maxParticipants, setMaxParticipants] = useState(25 as number);
  const [timeZone, setTimeZone] = useState(String);
  const [timeZones, setTimeZones] = useState([] as any[]);
  const [deadline, setDeadline] = useState("" as string);
  const [displayType, setDisplayType] = useState(0 as number);
  const [eventState, setEventState] = useState(EventEditState.Found as EventEditState);
  const [automaticReminder, setAutomaticReminder] = useState(false as boolean);

  const GetText = TextService.GetText;

  const EditEvent = async (id: number, formValues: any) => {
    if (eventState === EventEditState.Found) {
      let times: WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto[] = [];
      events.forEach(function (event) {
        times.push({ start: event.start, end: event.end, startUtc: getUtcDate(event.start, timeZone), endUtc: getUtcDate(event.end, timeZone), isFullDay: event.allDay })
      });

      return await EventService.UpdateEvent({
        id: id,
        name: formValues.name,
        timeZoneId: timeZone,
        displayType: displayType == 0 ? WebApiModelsEnumerationTypesDisplayType.NUMBER_0 : WebApiModelsEnumerationTypesDisplayType.NUMBER_1,
        deadline: deadline !== "" && moment(deadline).year() > 1970 ? new Date(deadline) : undefined,
        maxParticipants: maxParticipants,
        description: formValues.eventDescription,
        location: formValues.eventLocation,
        times: times,
        sendAutomaticReminder: automaticReminder
      });
    }

    if (eventState === EventEditState.HasBookings) {

      const createEventRequest: updateEventWithBookingsRequest = {
        id: id,
        name: formValues.name,
        displayType: displayType,
        deadline: deadline !== "" && moment(deadline).year() > 1970 ? new Date(deadline) : undefined,
        description: formValues.eventDescription,
        location: formValues.eventLocation,
      }

      return await EventService.UpdateEventWithBookings(createEventRequest);
    }

  };


  const CreateEventItem = async (slot: any) => {
    if (slot.action === "click") {
      let event: CalendarEvent = {
        title: "",
        id: Math.floor(Date.now() / 1000).toString(),
        allDay: false,
        desc: '',
        start: slot.start,
        end: addMinutes(new Date(slot.start), step),
        source: CalenderSource.EventBooky
      }
      events.push(event);

      setEvents(events => ([
        ...events,

      ]));
    }
  }

  const AddEvents = async (times: any) => {
    times.forEach((time: any) => {
      let event: CalendarEvent = {
        title: "",
        id: time.id,
        allDay: time.isFullDay,
        desc: '',
        start: new Date(time.start),
        end: new Date(time.end),
        source: CalenderSource.EventBooky
      }
      events.push(event);
    });


    setEvents(events => ([
      ...events,

    ]));
  }


  const UpdateEvent = (slot: any) => {
    events.forEach(function (event, index) {
      if (event.id === slot.event.id) {
        events[index].start = slot.start;
        events[index].end = slot.end;
      }
    });

    setEvents(events => ([
      ...events,

    ]));
  }

  const SetFullDayOnEvent = (id: string, isFullDay: boolean) => {
    events.forEach(function (event, index) {
      if (event.id === id) {
        events[index].allDay = isFullDay;
        events[index].title = isFullDay ? GetText("Calender_AllDay") : ""
      }
    });

    setEvents(events => ([
      ...events,

    ]));
  }

  const DeleteEvent = (id: string) => {
    setEvents(events => ([
      ...events.filter(item => item.id !== id),

    ]));
  }
  const changeStep = (step: number) => {
    setStep(step);
  }

  const LoadTimeZones = (defaultValue: string) => {
    const timeZonesWithUtc = getTimeZones({ includeUtc: false });
    let timeZones: any = [];
    timeZonesWithUtc.forEach(zone => {
      let selectLabel = zone.countryName + " (" + zone.mainCities.toString() + ")";
      timeZones.push({ "label": selectLabel, "value": zone.name })

      if (defaultValue != null && zone.name.toLocaleLowerCase() === defaultValue.toLocaleLowerCase()) {
        setTimeZone(zone.name);
      }
    });

    timeZones = timeZones.sort((a: any, b: any) => (a.label < b.label) ? -1 : 1);

    setTimeZones(timeZones);
  }

  return {
    EditEvent,
    CreateEventItem,
    UpdateEvent,
    DeleteEvent,
    events,
    step,
    timeZone,
    timeZones,
    setTimeZone,
    changeStep,
    LoadTimeZones,
    SetFullDayOnEvent,
    maxParticipants,
    setMaxParticipants,
    setDeadline,
    deadline,
    setDisplayType,
    displayType,
    AddEvents,
    eventState,
    setEventState,
    automaticReminder,
    setAutomaticReminder
  };
};