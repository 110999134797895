import { useEffect, useState } from 'react';
import { Button, message, Modal, Image, Tooltip } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { EventService } from '../../Services/EventService';
import 'react-multi-email/dist/style.css';
import { AuthProvider } from '../../Providers/authProvider';
import ImageSelect from '../../Components/Select/ImageSelect';
import { ImageService } from '../../Services/ImageService';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { BlobDto } from '../../Repository/eventbookyapi';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { EventBranding } from '../../Components/EventDetails/EventBranding';
import Title from 'antd/lib/typography/Title';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import UploadImage from '../../Components/Upload/UploadImage';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import { TextService } from '../../Services/TextService';
import LeftMenuEventDetails from './LeftMenuEventDetails';

const MyEventBranding = () => {
  const [event, setEvent] = useState([] as any);
  const [isModalOpen, setIsModalOpen] = useState(false as boolean);
  const [imageSelect, setImageSelect] = useState("" as string);
  const [images, setImages] = useState<BlobDto[]>([]);
  const [user, setUser] = useState({} as AuthenticatedUser | null);
  const GetText = TextService.GetText;

  let params = useParams();
  const onImageSelect = (image: string) => {
    setImageSelect(image);
  }


  const loadEvent = (id: string) => {
    EventService.GetEventDetails(id).then(result => {
      setEvent(result);
    });
  };

  const saveEventBannerImage = async (uri?: string) => {
    console.log(imageSelect.split("bloburl=")[1]);
    await EventService.UpdateBannerImage({
      webApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto: {
        eventId: event.eventId,
        bannerImagePath: imageSelect ? imageSelect.split("bloburl=")[1] : uri,
        isCustomBannerImage: false
      }
    });

    setImageSelect("");
    message.success(GetText("MyEventDetails_Saved"));
    loadEvent(event.eventUniqueId);
  }

  const deleteEventBannerImage = async () => {
    await EventService.UpdateBannerImage({
      webApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto: {
        eventId: event.eventId,
        bannerImagePath: "",
        isCustomBannerImage: false
      }
    });

    setImageSelect("");
    message.success(GetText("MyEventDetails_HasBeenRemoved"));
    loadEvent(event.eventUniqueId);
  }

  const loadImages = async () => {
    ImageService.GetBannerImages().then(result => {
      setImages(result);
    });
  }

  useEffect(() => {

    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    AuthProvider().ValidateUserLogin();
    if (params.id != null) {
      loadEvent(params.id);
      loadImages();
    }
  }, [params])


  return <LayoutLeftMenu hasTitleTop={true} titleTop={event.eventName} title={GetText("MyEventDetails_BrandTitle")} menu={<LeftMenuEventDetails selected={"brandevent"} />}>
    {GetText("MyEventDetails_BrandingDescription")}<br></br><br></br>

    <Title level={4}>{GetText("MyEventDetails_BannerImage")}</Title>
    {event.bannerImagePath ? <div style={{ marginBottom: "20px", marginTop: "20px" }}>
      {GetText("MyEventDetails_BrandingCurrentImage")} <Tooltip title={GetText("MyEventDetails_RemoveBranding")}><DeleteOutlined style={{ color: "red" }} onClick={deleteEventBannerImage} /></Tooltip>: <br></br>
      {event.bannerImagePath ? <Image width={400} height={100} preview={true} src={process.env.REACT_APP_API_URL + "/Image/downloadImage?bloburl=" + event.bannerImagePath} ></Image> : ""}
    </div> : ""}

    <Button onClick={() => { setIsModalOpen(true) }}>{GetText("MyEventDetails_SelectNewImage")}</Button>

    {user?.subscriptionLevel === SubscriptionLevel.Free ? <Button disabled={true} icon={<UploadOutlined />}>{GetText("MyEventDetails_UploadImageOnlyPremium")}</Button> :
      <UploadImage crop={true} isBanner={true} event={event} uploadFinish={saveEventBannerImage} />}

    <Modal title={GetText("MyEventDetails_SelectImage")} width={850} open={isModalOpen} onOk={() => { setIsModalOpen(false) }} onCancel={() => { setIsModalOpen(false) }}>
      <ImageSelect images={images} loadImages={loadImages} onImageSelect={onImageSelect} selectedImage={imageSelect} />
    </Modal>
    <br></br><br></br>


    <div style={{ marginBottom: "20px" }}>
      {imageSelect ? <Image width={200} height={100} preview={true} src={imageSelect} ></Image> : ""}
    </div>

    <BookyButton disabled={!imageSelect} onClick={imageSelect ? saveEventBannerImage : ""} text={GetText("MyEventDetails_SaveNewImage")} />

    <br></br><br></br>
    <Title level={4}>{GetText("MyEventDetails_ColorsForYourEvent")}</Title>
    <i>{GetText("MyEventDetails_SelectColors")}</i><br></br><br></br>
    <EventBranding event={event} />
  </LayoutLeftMenu>
};

export default MyEventBranding;