/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesDisplayType } from './WebApiModelsEnumerationTypesDisplayType';
import {
    WebApiModelsEnumerationTypesDisplayTypeFromJSON,
    WebApiModelsEnumerationTypesDisplayTypeFromJSONTyped,
    WebApiModelsEnumerationTypesDisplayTypeToJSON,
} from './WebApiModelsEnumerationTypesDisplayType';
import type { WebApiModelsEnumerationTypesEventState } from './WebApiModelsEnumerationTypesEventState';
import {
    WebApiModelsEnumerationTypesEventStateFromJSON,
    WebApiModelsEnumerationTypesEventStateFromJSONTyped,
    WebApiModelsEnumerationTypesEventStateToJSON,
} from './WebApiModelsEnumerationTypesEventState';
import type { WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto } from './WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto';
import {
    WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoToJSON,
} from './WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto';
import type { WebApiServicesDataTransferObjectsReplyServiceTimeResultDto } from './WebApiServicesDataTransferObjectsReplyServiceTimeResultDto';
import {
    WebApiServicesDataTransferObjectsReplyServiceTimeResultDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceTimeResultDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsReplyServiceTimeResultDtoToJSON,
} from './WebApiServicesDataTransferObjectsReplyServiceTimeResultDto';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
 */
export interface WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto {
    /**
     * Name of event
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventName?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesEventState}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventState?: WebApiModelsEnumerationTypesEventState;
    /**
     * Name of event
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    bannerImagePath?: string | null;
    /**
     * Name of event
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    notifyOnReply?: boolean;
    /**
     * Name of event
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    automaticReminder?: Date | null;
    /**
     * Name of event
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    automaticReminderSent?: Date | null;
    /**
     * Name of event
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventParticipants?: number;
    /**
     * Name of event
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventDeadline?: Date | null;
    /**
     * Descriptoion on event
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventDescription?: string | null;
    /**
     * Descriptoion on event
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventLocation?: string | null;
    /**
     * Descriptoion on event
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventUniqueId?: string | null;
    /**
     * Descriptoion on event
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventTimeZoneId?: string | null;
    /**
     * Descriptoion on event
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventId?: number;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesDisplayType}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    eventDisplayType?: WebApiModelsEnumerationTypesDisplayType;
    /**
     * 
     * @type {Array<WebApiServicesDataTransferObjectsReplyServiceTimeResultDto>}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    times?: Array<WebApiServicesDataTransferObjectsReplyServiceTimeResultDto> | null;
    /**
     * List of replies for each time
     * @type {Array<WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto>}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    timeAnswers?: Array<WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto> | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    bannerImageColorCode?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    primaryColorCode?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto
     */
    secondaryColorCode?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto {
    return WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
        'eventState': !exists(json, 'eventState') ? undefined : WebApiModelsEnumerationTypesEventStateFromJSON(json['eventState']),
        'bannerImagePath': !exists(json, 'bannerImagePath') ? undefined : json['bannerImagePath'],
        'notifyOnReply': !exists(json, 'notifyOnReply') ? undefined : json['notifyOnReply'],
        'automaticReminder': !exists(json, 'automaticReminder') ? undefined : (json['automaticReminder'] === null ? null : new Date(json['automaticReminder'])),
        'automaticReminderSent': !exists(json, 'automaticReminderSent') ? undefined : (json['automaticReminderSent'] === null ? null : new Date(json['automaticReminderSent'])),
        'eventParticipants': !exists(json, 'eventParticipants') ? undefined : json['eventParticipants'],
        'eventDeadline': !exists(json, 'eventDeadline') ? undefined : (json['eventDeadline'] === null ? null : new Date(json['eventDeadline'])),
        'eventDescription': !exists(json, 'eventDescription') ? undefined : json['eventDescription'],
        'eventLocation': !exists(json, 'eventLocation') ? undefined : json['eventLocation'],
        'eventUniqueId': !exists(json, 'eventUniqueId') ? undefined : json['eventUniqueId'],
        'eventTimeZoneId': !exists(json, 'eventTimeZoneId') ? undefined : json['eventTimeZoneId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventDisplayType': !exists(json, 'eventDisplayType') ? undefined : WebApiModelsEnumerationTypesDisplayTypeFromJSON(json['eventDisplayType']),
        'times': !exists(json, 'times') ? undefined : (json['times'] === null ? null : (json['times'] as Array<any>).map(WebApiServicesDataTransferObjectsReplyServiceTimeResultDtoFromJSON)),
        'timeAnswers': !exists(json, 'timeAnswers') ? undefined : (json['timeAnswers'] === null ? null : (json['timeAnswers'] as Array<any>).map(WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoFromJSON)),
        'bannerImageColorCode': !exists(json, 'bannerImageColorCode') ? undefined : json['bannerImageColorCode'],
        'primaryColorCode': !exists(json, 'primaryColorCode') ? undefined : json['primaryColorCode'],
        'secondaryColorCode': !exists(json, 'secondaryColorCode') ? undefined : json['secondaryColorCode'],
    };
}

export function WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDtoToJSON(value?: WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventName': value.eventName,
        'eventState': WebApiModelsEnumerationTypesEventStateToJSON(value.eventState),
        'bannerImagePath': value.bannerImagePath,
        'notifyOnReply': value.notifyOnReply,
        'automaticReminder': value.automaticReminder === undefined ? undefined : (value.automaticReminder === null ? null : value.automaticReminder.toISOString()),
        'automaticReminderSent': value.automaticReminderSent === undefined ? undefined : (value.automaticReminderSent === null ? null : value.automaticReminderSent.toISOString()),
        'eventParticipants': value.eventParticipants,
        'eventDeadline': value.eventDeadline === undefined ? undefined : (value.eventDeadline === null ? null : value.eventDeadline.toISOString()),
        'eventDescription': value.eventDescription,
        'eventLocation': value.eventLocation,
        'eventUniqueId': value.eventUniqueId,
        'eventTimeZoneId': value.eventTimeZoneId,
        'eventId': value.eventId,
        'eventDisplayType': WebApiModelsEnumerationTypesDisplayTypeToJSON(value.eventDisplayType),
        'times': value.times === undefined ? undefined : (value.times === null ? null : (value.times as Array<any>).map(WebApiServicesDataTransferObjectsReplyServiceTimeResultDtoToJSON)),
        'timeAnswers': value.timeAnswers === undefined ? undefined : (value.timeAnswers === null ? null : (value.timeAnswers as Array<any>).map(WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoToJSON)),
        'bannerImageColorCode': value.bannerImageColorCode,
        'primaryColorCode': value.primaryColorCode,
        'secondaryColorCode': value.secondaryColorCode,
    };
}

