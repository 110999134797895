import { useEffect, useState } from 'react';
import { Col, Form, Modal, message } from 'antd';
import { BookyInput } from '../Forms/BookyInput';
import { BookyButton } from '../Buttons/BookyButton';
import { GroupService } from '../../Services/GroupService';
import { TextService } from '../../Services/TextService';

export const CreateGroupButton = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const GetText = TextService.GetText;

    const onCancel = () => {
        form.setFieldValue("groupName", "");
        setOpen(false);
    };

    useEffect(() => {
    }, [form])

    const onCreate = async (values: any) => {
        try {
            await GroupService.CreateGroup({
                name: values.groupName,
                members: []
            });
        } catch (error) {
            message.warning(GetText("CreateGroup_ErrorAlreadyExist"));
            return;
        }
        form.setFieldValue("groupName", "");
        setOpen(false);

        props.reloadGroup();
    }

    return (
        <>
            <Modal
                open={open}
                title={GetText("CreateGroup_ModalHeader")}
                okText={GetText("CreateGroup_CreateText")}
                cancelText={GetText("CreateGroup_CancelText")}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={onCancel}
            >
                <Form form={form} disabled={props.user.hasOnlyReadRights} onFinish={onCreate}>
                    <Col span={17}>
                        <BookyInput name="groupName" requiredMessage={GetText("CreateGroup_RequiredText")} placeholder={GetText("CreateGroup_PlaceHolderText")} />
                    </Col>
                </Form>
            </Modal >

            <div>
                <BookyButton
                    type="primary"
                    disabled={props.user.hasOnlyReadRights}
                    fullWidth={false}
                    text={GetText("CreateGroup_CreateText")}
                    onClick={() => {
                        setOpen(true);
                    }}
                >

                </BookyButton>
            </div>
        </>
    );
};

export default CreateGroupButton;