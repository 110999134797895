import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';
import { BookyCheckboxList } from '../Forms/BookyCheckboxList';
import { WebApiServicesDataTransferObjectsGroupServiceGroupResultDto, WebApiMemberDtoMemberDto, WebApiMemberDtoGroupDto } from '../../Repository/eventbookyapi';
import { MemberService } from '../../Services/MemberService';
import { BookyDynamicInput } from '../Forms/BookyDynamicInput';
import { MemberFieldTypes } from '../../Types/MemberFieldTypes';
import { BookyCheckbox } from '../Forms/BookyCheckbox';
import { BookyPhoneInput } from '../Forms/BookyPhoneInput';
import { OneFrontModal } from '../Modals/OneFrontModal';
import { CompanyRole } from '../../Types/CompanyRole';
import { EmailNotificationPreference } from '../../Types/EmailNotificationPreference';
import { BookyDynamicCheckbox } from '../Forms/BookyDynamicCheckbox';
import { MemberStatusType } from '../../Types/MemberStatusType';

export const EditMember = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const GetText = TextService.GetText;
    const [groups, setGroups] = useState([]);

    const onCancel = () => {
        form.setFieldValue("groupName", "");
        setOpen(false);
    };

    useEffect(() => {
        if (props.groups) {
            let groups: any = [];

            props.groups.forEach((group: WebApiMemberDtoGroupDto) => {
                if (!group.isDefaultGroup) {
                    groups.push({ value: group.id, label: group.name });
                }
            });
            setGroups(groups);
        }

    }, [props])

    useEffect(() => {
        form.setFieldValue("firstName", props.member.firstName);
        form.setFieldValue("lastName", props.member.lastName);
        form.setFieldValue("phoneNumber", props.member.phoneNumber);
        form.setFieldValue("email", props.member.email);
        form.setFieldValue("email2", props.member.email2);
        form.setFieldValue("address", props.member.address);
        form.setFieldValue("postalCode", props.member.postalCode);
        form.setFieldValue("postalArea", props.member.postalArea);
        form.setFieldValue("company", props.member.company);
        form.setFieldValue("country", props.member.country);
        form.setFieldValue("uniqueId", props.member.uniqueId);
        form.setFieldValue("groups", props.member.groups);
        form.setFieldValue("title", props.member.title);
        form.setFieldValue("extra1", props.member.extra1);
        form.setFieldValue("extra2", props.member.extra2);
        form.setFieldValue("extra3", props.member.extra3);
        form.setFieldValue("status", props.member.status == 1 ? true : false);
        form.setFieldValue("emailNotificationPreference", props.member.emailNotificationPreference == 1 ? true : false);
    }, [form, props.member])

    const setFieldCustomError = (fieldName: string, error: string,) => {
        form.setFields([
            {
                name: fieldName, // required
                errors: [error],
            },
        ]);
    }

    const onCreate = async (values: any) => {

        let foundError = false;
        props.members.forEach((member: WebApiMemberDtoMemberDto) => {
            if (member.memberId != props.member.memberId) {
                if (member.uniqueId == values.uniqueId) {
                    setFieldCustomError("uniqueId", "Finns redan en medlem med id " + member.uniqueId);
                    foundError = true;
                }

                if (member.email == values.email) {
                    setFieldCustomError("email", "Finns redan en medlem med epost " + member.email)
                    foundError = true;
                }
            }
        });

        if (foundError) {
            return true;
        }

        try {
            await MemberService.UpdateMember({
                memberId: props.member.memberId,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                email2: values.email2,
                phoneNumber: values.phoneNumber,
                address: values.address,
                postalArea: values.postalArea,
                postalCode: values.postalCode,
                country: values.country,
                company: values.company,
                uniqueId: values.uniqueId,
                title: values.title,
                extra1: values.extra1,
                extra2: values.extra2,
                extra3: values.extra3,
                groups: values.groups,
                status: values.status == true ? MemberStatusType.Cancelled : MemberStatusType.Active,
                emailNotificationPreference: values.emailNotificationPreference == true ? EmailNotificationPreference.NoInformationEmails : EmailNotificationPreference.Unspecified
            });
        } catch (error) {
            message.warning(GetText("EditMember_ErrorSaving"));
            return;
        }
        setOpen(false);

        props.loadMembers();
    }

    const onPhoneChange = (value: any) => {
        form.setFieldValue("phoneNumber", value);
    }

    return (
        <>
            <OneFrontModal
                open={open}
                title={GetText("EditMember_ModalTitle")}
                okText={GetText("EditMember_ModalOk")}
                cancelText={GetText("EditMember_ModalCancel")}
                width={700}
                onCancel={onCancel}
                okButtonProps={{ disabled: props.user.hasOnlyReadRights }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                {props.member.unconfirmedEmail && props.member.unconfirmedEmail.length > 0 ? <i>Medlemmen har inte slutfört registreringen ännu. Registrerade sig med {props.member.unconfirmedEmail}</i> : ""}
                <Form disabled={props.user.hasOnlyReadRights} colon={false} form={form} labelAlign={"left"} layout='vertical' onFinish={onCreate}>
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.UniqueId} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.FirstName} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.LastName} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.Email} isAdmin={true} memberFieldSettings={props.memberFieldSettings} validateEmailIfFilledIn={true} />
                            </Col>
                            <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.Email2} isAdmin={true} memberFieldSettings={props.memberFieldSettings} validateEmailIfFilledIn={true} />

                            </Col>
                        </Row>

                        <BookyPhoneInput label={GetText("EditMember_PhoneNumber")} disabled={props.user.hasOnlyReadRights} name="phoneNumber" onchange={onPhoneChange} req placeholder={""} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Address} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />

                        <Row gutter={24}>
                            <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.PostalCode} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                            </Col>
                            <Col xs={24} sm={10} md={18} lg={18} xl={18}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.PostalArea} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                            </Col>
                        </Row>

                        <BookyDynamicInput fieldType={MemberFieldTypes.Title} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Country} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Company} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Extra1} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Extra2} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Extra3} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyCheckboxList text={GetText("EditMember_Groups")} options={groups} name="groups" req placeholder={GetText("EditMember_Groups")} />
                        <BookyCheckbox alignLabelRight={true} label={"Avslutad"} name="status" />
                        <BookyDynamicCheckbox fieldType={MemberFieldTypes.EmailNotificationPreference} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                    </Col>
                </Form>
            </OneFrontModal>

            <EditOutlined
                style={{ fontSize: "20px" }}
                type="primary"
                onClick={() => {
                    setOpen(true);
                }}
            >
            </EditOutlined>
        </>
    );
};

export default EditMember;