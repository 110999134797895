import { GroupApi, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto, WebApiServicesDataTransferObjectsGroupServiceSendMailToGroupDto, WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto } from "../Repository/eventbookyapi";
import { createGroupRequest } from "../Types/RequestResponse/createGroupRequest";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class GroupService {
    public static CreateGroup = async (request: createGroupRequest) => {
        return new GroupApi(GetApiConfiguration()).apiGroupPostRaw({
            webApiServicesDataTransferObjectsGroupServiceCreateGroupDto: {
                name: request.name,
                members: request.members
            }
        });
    };

    public static SendMailToGroup = async (request: WebApiServicesDataTransferObjectsGroupServiceSendMailToGroupDto) => {
        return new GroupApi(GetApiConfiguration()).apiGroupGroupidSendMailPost({
            groupid: request.groupId!.toString(),
            webApiServicesDataTransferObjectsGroupServiceSendMailToGroupDto: request
        });
    };

    public static IsAllowedToSendGroupMails = async () => {
        return new GroupApi(GetApiConfiguration()).apiGroupIsAllowedToSendGroupMailsGet();
    };

    public static GetGroups = async () => {
        return new GroupApi(GetApiConfiguration()).apiGroupGet({ includeAllMembersGroup: false });
    };

    public static GetGroupsIncludingAllMembers = async () => {
        return new GroupApi(GetApiConfiguration()).apiGroupGet({ includeAllMembersGroup: true });
    };


    public static DeleteGroup = async (groupId: number) => {
        return new GroupApi(GetApiConfiguration()).apiGroupGroupIdDelete({
            groupId: groupId
        });
    };

    public static AddGroupMembers = async (groupId: number, members: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto[]) => {
        return new GroupApi(GetApiConfiguration()).apiGroupGroupIdMembersPost({
            groupId: groupId.toString(),
            webApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto: {
                id: groupId,
                members: members
            }
        });
    };

    public static EditGroup = async (request: WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto) => {
        return new GroupApi(GetApiConfiguration()).apiGroupUpdatePost({
            webApiServicesDataTransferObjectsGroupServiceUpdateGroupDto: request
        });
    };
};