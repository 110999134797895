import { BellFilled, BellOutlined, CheckCircleOutlined, EditOutlined } from "@ant-design/icons"
import { Col, message, Popconfirm, Row, Space, Switch, Tooltip, } from "antd"
import Title from "antd/lib/typography/Title"
import { useState } from "react"
import { formatDateString } from "../../Helpers/DateHelpers"
import { EventService } from "../../Services/EventService"
import { TextService } from "../../Services/TextService"

export const EventDetails = (props: any) => {
    const [showConfirm, setShowConfirm] = useState(false as boolean);
    const GetText = TextService.GetText;

    const onConfirmOpenEvent = async () => {
        const result = await EventService.OpenEvent(props.event.eventId);
        props.onOpenPoll();
    }

    const updateNotificationsForEvent = async (notification: boolean) => {
        await EventService.UpdateNotificationsForEvent(props.event.eventId, notification);
        message.destroy();
        message.success(GetText("EventDetails_ResetColors"));
        props.onNotificationUpdate();
    }

    return <>


        <Space style={{ width: '80%', justifyContent: 'left', margin: 0 }}>
            <Title>Status & Svar</Title>
        </Space>

        <Row gutter={[40, 40]}>
            <Col style={{ textAlign: "left" }} span={6}>
                {GetText("EventDetails_EventStatus")}
            </Col>

            <Col style={{ textAlign: "left" }} span={12}>
                {props.event.eventState === 0 ? <span className='accepted'><CheckCircleOutlined /> {GetText("EventDetails_PollIsOpen")} </span> : <span className='rejected'>{GetText("EventDetails_PollIsClosed")} <EditOutlined onClick={(e) => { setShowConfirm(true) }} />
                    <Popconfirm
                        title={GetText("EventDetails_EventOpenQuestion")}
                        key={props.event.eventId}
                        open={showConfirm}
                        onCancel={(e) => { setShowConfirm(false) }}
                        placement="topRight"
                        onConfirm={onConfirmOpenEvent}
                    >
                    </Popconfirm>
                </span>}
            </Col>
        </Row>

        {props.event.eventDescription ?
            <Row gutter={[40, 40]}>
                <Col style={{ textAlign: "left" }} span={6}>
                    {GetText("EventDetails_EventDescription")}
                </Col>

                <Col style={{ textAlign: "left" }} span={12}>
                    {props.event.eventDescription}
                </Col>
            </Row> : ""}

        {props.event.eventLocation ?
            <Row gutter={[40, 40]}>
                <Col style={{ textAlign: "left" }} span={6}>
                    {GetText("EventDetails_EventLocation")}
                </Col>

                <Col style={{ textAlign: "left" }} span={12}>
                    {props.event.eventLocation}
                </Col>
            </Row> : ""}

        <Row gutter={[40, 40]}>
            <Col style={{ textAlign: "left" }} span={6}>
                {GetText("EventDetails_EventTimeZone")}
            </Col>

            <Col style={{ textAlign: "left" }} span={12}>
                {props.event.eventTimeZoneId}
            </Col>
        </Row>

        <Row gutter={[40, 40]}>
            <Col style={{ textAlign: "left" }} span={6}>
                {GetText("EventDetails_EventParticipants")}
            </Col>

            <Col style={{ textAlign: "left" }} span={12}>
                {props.event.eventParticipants}
            </Col>
        </Row>

        {props.event.eventDeadline ?
            <Row gutter={[40, 40]}>
                <Col style={{ textAlign: "left" }} span={6}>
                    {GetText("EventDetails_EventDeadline")}
                </Col>

                <Col style={{ textAlign: "left" }} span={12}>
                    {formatDateString(props.event.eventDeadline)}
                </Col>
            </Row> : ""}

        <Row gutter={[40, 40]}>
            <Col style={{ textAlign: "left" }} span={6}>
                {GetText("EventDetails_NotificationToolTip")}
            </Col>

            <Col style={{ textAlign: "left" }} span={12}>
                <Switch checked={props.event.notifyOnReply} onChange={(e) => { updateNotificationsForEvent(!props.event.notifyOnReply) }} checkedChildren={<span><BellFilled />{GetText("EventDetails_EnabledNotification")}</span>} unCheckedChildren={<span><BellOutlined />{GetText("EventDetails_DisabledNotification")}</span>} defaultChecked />
            </Col>
        </Row>

    </>
}