/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesCompanyTypeConfiguration } from './WebApiModelsEnumerationTypesCompanyTypeConfiguration';
import {
    WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSON,
    WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSONTyped,
    WebApiModelsEnumerationTypesCompanyTypeConfigurationToJSON,
} from './WebApiModelsEnumerationTypesCompanyTypeConfiguration';
import type { WebApiModelsEnumerationTypesPaymenConfiguration } from './WebApiModelsEnumerationTypesPaymenConfiguration';
import {
    WebApiModelsEnumerationTypesPaymenConfigurationFromJSON,
    WebApiModelsEnumerationTypesPaymenConfigurationFromJSONTyped,
    WebApiModelsEnumerationTypesPaymenConfigurationToJSON,
} from './WebApiModelsEnumerationTypesPaymenConfiguration';
import type { WebApiModelsEnumerationTypesRole } from './WebApiModelsEnumerationTypesRole';
import {
    WebApiModelsEnumerationTypesRoleFromJSON,
    WebApiModelsEnumerationTypesRoleFromJSONTyped,
    WebApiModelsEnumerationTypesRoleToJSON,
} from './WebApiModelsEnumerationTypesRole';
import type { WebApiModelsEnumerationTypesSubscriptionLevel } from './WebApiModelsEnumerationTypesSubscriptionLevel';
import {
    WebApiModelsEnumerationTypesSubscriptionLevelFromJSON,
    WebApiModelsEnumerationTypesSubscriptionLevelFromJSONTyped,
    WebApiModelsEnumerationTypesSubscriptionLevelToJSON,
} from './WebApiModelsEnumerationTypesSubscriptionLevel';

/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.AuthenticateAsync(WebApi.Services.DataTransferObjects.UserService.AuthenticateReqDto) response.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto {
    /**
     * Gets or sets the identifier.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    id?: string;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    firstName?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesSubscriptionLevel}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    subscriptionLevel?: WebApiModelsEnumerationTypesSubscriptionLevel;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    hasPaidSubscription?: boolean;
    /**
     * Gets or sets the Name.
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    subscriptionIsActive?: boolean;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    email?: string | null;
    /**
     * Gets or sets the token.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    token?: string | null;
    /**
     * Gets or sets the token.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    tokenExpires?: Date;
    /**
     * Gets or sets the token.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    companyId?: number;
    /**
     * Gets or sets the token.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    companyLanguage?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesRole}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    role?: WebApiModelsEnumerationTypesRole;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesCompanyTypeConfiguration}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    companyType?: WebApiModelsEnumerationTypesCompanyTypeConfiguration;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesPaymenConfiguration}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    paymentConfiguration?: WebApiModelsEnumerationTypesPaymenConfiguration;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    hasOnlyReadRights?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    maxEmails?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    maxAdministrators?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto
     */
    maxMembers?: number;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceAuthenticateResDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto {
    return WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'subscriptionLevel': !exists(json, 'subscriptionLevel') ? undefined : WebApiModelsEnumerationTypesSubscriptionLevelFromJSON(json['subscriptionLevel']),
        'hasPaidSubscription': !exists(json, 'hasPaidSubscription') ? undefined : json['hasPaidSubscription'],
        'subscriptionIsActive': !exists(json, 'subscriptionIsActive') ? undefined : json['subscriptionIsActive'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'tokenExpires': !exists(json, 'tokenExpires') ? undefined : (new Date(json['tokenExpires'])),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyLanguage': !exists(json, 'companyLanguage') ? undefined : json['companyLanguage'],
        'role': !exists(json, 'role') ? undefined : WebApiModelsEnumerationTypesRoleFromJSON(json['role']),
        'companyType': !exists(json, 'companyType') ? undefined : WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSON(json['companyType']),
        'paymentConfiguration': !exists(json, 'paymentConfiguration') ? undefined : WebApiModelsEnumerationTypesPaymenConfigurationFromJSON(json['paymentConfiguration']),
        'hasOnlyReadRights': !exists(json, 'hasOnlyReadRights') ? undefined : json['hasOnlyReadRights'],
        'maxEmails': !exists(json, 'maxEmails') ? undefined : json['maxEmails'],
        'maxAdministrators': !exists(json, 'maxAdministrators') ? undefined : json['maxAdministrators'],
        'maxMembers': !exists(json, 'maxMembers') ? undefined : json['maxMembers'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'subscriptionLevel': WebApiModelsEnumerationTypesSubscriptionLevelToJSON(value.subscriptionLevel),
        'hasPaidSubscription': value.hasPaidSubscription,
        'subscriptionIsActive': value.subscriptionIsActive,
        'email': value.email,
        'token': value.token,
        'tokenExpires': value.tokenExpires === undefined ? undefined : (value.tokenExpires.toISOString()),
        'companyId': value.companyId,
        'companyLanguage': value.companyLanguage,
        'role': WebApiModelsEnumerationTypesRoleToJSON(value.role),
        'companyType': WebApiModelsEnumerationTypesCompanyTypeConfigurationToJSON(value.companyType),
        'paymentConfiguration': WebApiModelsEnumerationTypesPaymenConfigurationToJSON(value.paymentConfiguration),
        'hasOnlyReadRights': value.hasOnlyReadRights,
        'maxEmails': value.maxEmails,
        'maxAdministrators': value.maxAdministrators,
        'maxMembers': value.maxMembers,
    };
}

