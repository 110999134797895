import { UserApi } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "../Services/ServiceConfiguration";
import { AuthenticatedUser } from "../Types/AuthenticatedUser";
import { CachedUser } from "../Types/CachedUser";
import { SubscriptionLevel } from "../Types/SubscriptionLevel";
import dayjs from "dayjs";

export const AuthProvider = () => {
  const SetUser = async (authenticatedUser: AuthenticatedUser) => {
    user = authenticatedUser;
    localStorage.setItem("eventbookycacheduser", JSON.stringify({
      token: authenticatedUser.token,
      tokenExpires: authenticatedUser.tokenExpires
    }));
  };

  const Logout = async () => {
    localStorage.removeItem("eventbookycacheduser");
  };

  const GetLoggedInUser = (refresh?: boolean): Promise<AuthenticatedUser | null> => {
    let cachedUserValue = localStorage.getItem("eventbookycacheduser");

    if (cachedUserValue) {
      let cachedUser = JSON.parse(cachedUserValue) as CachedUser;
      if (cachedUser != null && dayjs(cachedUser.tokenExpires) > dayjs()) {
        if (user && !refresh) {
          return Promise.resolve(user);
        }
        else {
          // No user in state, refresh from server
          return new UserApi(GetApiConfiguration()).apiUserGetLoggedInUserGet().then(response => {
            user = {
              id: response.id ? response.id : "",
              companyId: response.companyId ? response.companyId : -1,
              firstName: response.firstName ? response.firstName : "",
              lastName: response.lastName ? response.lastName : "",
              email: response.email ? response.email : "",
              token: cachedUser.token,
              tokenExpires: cachedUser.tokenExpires,
              companyLanguage: response.companyLanguage,
              subscriptionLevel: response.subscriptionLevel ? response.subscriptionLevel as SubscriptionLevel : SubscriptionLevel.Free,
              hasPaidSubscription: response.hasPaidSubscription,
              role: response.role,
              companyType: response.companyType,
              paymentConfiguration: response.paymentConfiguration,
              hasOnlyReadRights: response.hasOnlyReadRights,
              maxAdministrators: response.maxAdministrators,
              maxEmails: response.maxEmails,
              maxMembers: response.maxMembers

            } as AuthenticatedUser;

            SetUser(user);
            return user;
          }).catch(response => {
            localStorage.removeItem("eventbookycacheduser");
          });
        }
      }
      else {
        localStorage.removeItem("eventbookycacheduser");
      }
    }
    return Promise.resolve(null)
  };

  const ValidateUserLogin = async () => {
    const userLoggedIn = await GetLoggedInUser();
    if (userLoggedIn === null || userLoggedIn.token === null) {
      window.location.href = "/Login?redirect=" + window.location.pathname;
    }
  }

  const GetToken = (): String | null => {
    let cachedUserValue = localStorage.getItem("eventbookycacheduser");

    if (cachedUserValue) {
      let cachedUser = JSON.parse(cachedUserValue) as CachedUser;
      return cachedUser.token;
    }

    return null;
  };

  return {
    SetUser,
    GetLoggedInUser,
    GetToken,
    Logout,
    ValidateUserLogin
  };
};