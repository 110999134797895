import { Divider, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CheckOutlined } from '@ant-design/icons';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { TextService } from '../../Services/TextService';
import { enterpriseDataOptions, premiumDataOptions, getPricingTableColumns } from './PricingTable';

const FeatureBoxEnterprise = (props: any) => {
    const GetText = TextService.GetText;

    const isPossibleToUpgrade = () => {
        if (props.user?.subscriptionLevel === SubscriptionLevel.Free || props.user?.subscriptionLevel === SubscriptionLevel.Basic || props.user?.subscriptionLevel === SubscriptionLevel.BasicAdvanced || props.user?.subscriptionLevel === SubscriptionLevel.BasicPlus || props.user?.subscriptionLevel === SubscriptionLevel.Premium) {
            return true;
        }

        return false;
    }

    const isCurrentPlan = () => {
        return props.user != null && props.user.subscriptionLevel === SubscriptionLevel.Enterprise
    }

    return <div className="bookyBox pricingBox">
        <div className='descriptionContainer'>

            <Title>{props.title}</Title>
            <div className='description'>{props.pricing}</div>
            <div className="description">{props.mail}</div>
            <div className="description">{props.description}</div>
        </div>
        <Divider style={{ backgroundColor: "var(--color-primary)", }} />
        <>
            <Table
                className='basic-feature-table'
                columns={getPricingTableColumns(TextService)}
                dataSource={enterpriseDataOptions}
                pagination={false}
            /></>

        {isCurrentPlan() && !isPossibleToUpgrade() ?
            <BookyButton size="large" disabled={true} fullWidth={true} type="primary" text={GetText("Pricing_CurrentPlan")} /> : ""
        }

        {
            isPossibleToUpgrade() ?
                <BookyButton size="large" disabled={false} fullWidth={true} onClick={() => props.initiatePayment(SubscriptionLevel.Enterprise)} type="primary" text={props.upgradeToText} /> :
                ""
        }


        {
            props.user == null || !isPossibleToUpgrade() && !isCurrentPlan() ?
                <BookyButton size="large" onClick={() => { props.redirectToCreateUser(SubscriptionLevel.Enterprise) }} fullWidth={true} type="primary" text={GetText("Pricing_SignUp")} /> : ""
        }
        <Divider style={{ backgroundColor: "var(--color-primary)", }} />
        <div className='pricing-features'>
            {
                props.featureList?.map(function (item: string) {
                    return <><CheckOutlined /> <div className='feature'>{item}</div></>
                })
            }
        </div>
    </div >
};

export default FeatureBoxEnterprise;