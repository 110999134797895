import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Layout, Row, Skeleton, Space, Table } from 'antd';
import { useNavigate } from "react-router-dom";
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { SubscriptionService } from '../../Services/SubscriptionService';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import LeftMenuSettings from './LeftSettings';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { CompanyRole } from '../../Types/CompanyRole';
import { WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse, WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto } from '../../Repository/eventbookyapi';
import { formatToShortFormat, getCurrentYearAsString } from '../../Helpers/DateHelpers';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { CompanyService } from '../../Services/CompanyService';
import InnerHTML from 'dangerously-set-html-content'
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import { mapSubscriptionLevel } from '../../Mappers/mapSubscriptionLevel';

const Payments = () => {
  const navigation = useNavigate();
  const [accountInformation, setAccountInformation] = useState({} as WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [initiatePaymentResponse, setInitiatePaymentResponse] = useState({} as WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse);
  const [limits, setLimits] = useState([] as any);

  const [user, setUser] = useState({} as AuthenticatedUser);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user!);
      loadEvents(user!);
    });

  }, []);

  const initiatePayment = async () => {
    setIsLoading(true);
    CompanyService.InitiatePayment(user.subscriptionLevel).then(result => {
      setInitiatePaymentResponse(result);
      setIsLoading(false);
    });
  };

  const loadEvents = (user: AuthenticatedUser) => {
    SubscriptionService.GetAccount().then(result => {
      setAccountInformation(result);

      let limits = [
        {
          limit: "Antal medlemmar",
          current: result.countAccountMembers,
          max: user.maxMembers,
        },
        {
          limit: "Antal administratörer",
          current: result.countAccountAdmins,
          max: user.maxAdministrators,
        },
        {
          limit: "Antal skickade e-post  för nuvarande abonnemangsperiod",
          current: result.emailCountSentCurrentYear,
          max: user.maxEmails,
        }
      ]

      setLimits(limits);

    });
  }


  const RedirectToCreateAccount = () => {
    navigation("/" + getCurrentLanguage() + "/pricing")
  }

  const columns = [
    {
      title: '',
      dataIndex: 'limit',
      key: 'limit',
    },
    {
      title: 'Aktuell status',
      dataIndex: 'current',
      key: 'current',
    },
    {
      title: 'Max enligt abonnemang (' + mapSubscriptionLevel(user.subscriptionLevel) + ")",
      dataIndex: 'max',
      key: 'max',
    },

  ];

  return <LayoutLeftMenu fullpageMain={true} title={GetText("Settings_Account")} menu={<LeftMenuSettings selected={"payments"} />}>
    <Col span={16}>
      <Col className='bookyBox' style={{ marginLeft: "20px" }}>
        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
          <h3>{GetText("Settings_Account")}</h3>
        </Space>
        {GetText("Settings_Subscription")}: {mapSubscriptionLevel(user?.subscriptionLevel)}<br />

        {user?.subscriptionLevel == SubscriptionLevel.Free ? <Alert showIcon={true} type="info" message={GetText("Settings_FreeAccountDescription")} action={
          <Button onClick={RedirectToCreateAccount} size="small" type="primary">
            {GetText("Settings_ReadMorePremium")}
          </Button>} /> : ""}

        {user?.hasPaidSubscription ? <span><br />Kontot betalt t.om. {formatToShortFormat(accountInformation?.nextSubscriptionRenewalDate)}</span> : ""} <br />


        <Table style={{ width: "100%" }} columns={columns} dataSource={limits} pagination={false}>
        </Table>
      </Col>

      {user?.role === CompanyRole.GlobalAdmin && user.hasPaidSubscription ?
        <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "20px" }} span={24}>
          <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
            <h3>{GetText("Settings_Payment")}</h3>
          </Space>
          Vll du förlänga ett år av OneFront? Klicka på knappen nedan. Vill du byta nivå? Klicka på "Priser" i meny. Du kan när som helst uppgradera nivå vid behov. <br /><br />
          <BookyButton
            loading={isLoading}
            disabled={!accountInformation.allowDirectPayment}
            type="primary"
            fullWidth={false}
            text={"Förläng 1 år's abonnemang av OneFront"}
            onClick={initiatePayment}
          >
          </BookyButton>
          {!accountInformation?.allowDirectPayment ? <i><br />Kontot är redan betalt. Du kan tidigast förlänga tjänsten 6 månader innan din nuvarande prenumeration går ut ({formatToShortFormat(accountInformation.nextPossibleExtendedDate)}).</i> : ""}
          <br />
          {initiatePaymentResponse?.snippet != null ? <InnerHTML className='paymentIntegraitonContainer' html={initiatePaymentResponse?.snippet!} /> : ""}
        </Col>
        : ""}


    </Col>
  </LayoutLeftMenu>


};

export default Payments;