import { Checkbox, Col, Form, Input, Row } from "antd";
import { validateEmail } from "../../Helpers/Validators";
import { getMemberFieldSetting } from "../../Helpers/MemberFieldsHelper";
import { MemberFieldTypes } from "../../Types/MemberFieldTypes";
import { TextService } from "../../Services/TextService";
import { mapDynamicInputLabel } from "../../Mappers/mapDynamicInputLabel";
import { mapDynamicInputName } from "../../Mappers/mapDynamicName";
import { mapDynamicRequiredMessage } from "../../Mappers/mapDynamicRequiredMessage";

export const BookyDynamicCheckbox = (props: any) => {

  const field = getMemberFieldSetting(props.fieldType, props.memberFieldSettings);
  const GetText = TextService.GetText;
  let rules: any = [];
  if (props.isAdmin && field.mandatoryAdmin || !props.isAdmin && field.mandatoryPublic) {
    rules.push({
      required: true,
      message: mapDynamicRequiredMessage(field.columnType!),
    });
  }

  const isPublicUniqueIdField = !props.isAdmin && field.columnType === MemberFieldTypes.UniqueId;
  if (isPublicUniqueIdField) {
    rules = [];
  }

  if (field.visibleInAdmin && props.isAdmin || field.visiblePublic && !props.isAdmin) {

    return (
      <Row>
        <Col>
          <Form.Item colon={false} name={mapDynamicInputName(field.columnType!)} valuePropName="checked"
            rules={rules}
          >
            {(() => {
              return <Checkbox type={props.type} disabled={isPublicUniqueIdField} />
            })()
            }
          </Form.Item >
        </Col>
        <Col style={{ marginLeft: "10px", marginTop: "3px" }}>{mapDynamicInputLabel(field.columnType!)}</Col>
      </Row>)
  }

  return (null);
}