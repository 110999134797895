import { useState } from "react";
import { CalendarEvent, CalenderSource } from "../Types/CalendarEvent";
import { createEventRequest, time } from '../Types/RequestResponse/createEventResponse';
import { EventService } from '../Services/EventService';
import addMinutes from "date-fns/addMinutes";
import { getTimeZones } from "@vvo/tzdb";
import { getUtcDate } from "../Helpers/DateHelpers";
import { TextService } from "../Services/TextService";

export const useCreateEvent = () => {
  const [events, setEvents] = useState([] as CalendarEvent[]);
  const [step, setStep] = useState(60 as number);
  const [maxParticipants, setMaxParticipants] = useState(25 as number);
  const [timeZone, setTimeZone] = useState(String);
  const [timeZones, setTimeZones] = useState([] as any[]);
  const [deadline, setDeadline] = useState("" as string);
  const [displayType, setDisplayType] = useState(0 as number);
  const [automaticReminder, setAutomaticReminder] = useState(false as boolean);
  const GetText = TextService.GetText;

  const CreateEvent = async (formValues: any) => {
    let times: time[] = [];
    events.forEach(function (event) {
      times.push({ start: event.start, end: event.end, startUtc: getUtcDate(event.start, timeZone), endUtc: getUtcDate(event.end, timeZone), isFullDay: event.allDay })
    });

    const createEventRequest: createEventRequest = {
      name: formValues.name,
      timezoneid: timeZone,
      displayType: displayType,
      answerOptions: formValues.answerOptions,
      deadline: deadline !== "" ? new Date(deadline) : undefined,
      maxParticipants: maxParticipants,
      description: formValues.eventDescription,
      location: formValues.eventLocation,
      sendAutomaticReminder: automaticReminder,
      times: times
    }

    return await EventService.CreateEvent(createEventRequest);
  };


  const CreateEventItem = async (slot: any) => {
    if (slot.action === "click") {
      let event: CalendarEvent = {
        title: "",
        id: (new Date()).getTime().toString(),
        allDay: step == -2 ? true : false,
        desc: '',
        start: slot.start,
        end: addMinutes(new Date(slot.start), step),
        source: CalenderSource.EventBooky
      }
      events.push(event);

      setEvents(events => ([
        ...events,

      ]));
    }
  }

  const SetGoogleEvents = async (googleEvents: any[]) => {

    if (!googleEvents) {
      return;
    }

    var newEvents = [...events]; // make a separate copy of the array
    newEvents = events.filter(p => p.source == CalenderSource.EventBooky);

    googleEvents.forEach(googleEvent => {
      if (googleEvent.start.dateTime != undefined) {

        let event: CalendarEvent = {
          title: googleEvent.summary,
          id: googleEvent.id,
          allDay: false,
          desc: googleEvent.summary,
          start: new Date(googleEvent.start.dateTime),
          end: new Date(googleEvent.end.dateTime),
          source: CalenderSource.Google
        }
        newEvents.push(event);
      }
    });

    setEvents(newEvents);
  }

  const UpdateEvent = (slot: any) => {
    events.forEach(function (event, index) {
      if (event.id === slot.event.id) {
        events[index].start = slot.start;
        events[index].end = slot.end;
      }
    });

    setEvents(events => ([
      ...events,

    ]));
  }

  const SetFullDayOnEvent = (id: string, isFullDay: boolean) => {
    events.forEach(function (event, index) {
      if (event.id === id) {
        events[index].allDay = isFullDay;
        events[index].title = isFullDay ? GetText("Calender_AllDay") : ""
      }
    });

    setEvents(events => ([
      ...events,

    ]));
  }

  const DeleteEvent = (id: string) => {
    setEvents(events => ([
      ...events.filter(item => item.id !== id),

    ]));
  }
  const changeStep = (step: number) => {
    setStep(step);
  }

  const LoadTimeZones = () => {
    const timeZonesWithUtc = getTimeZones({ includeUtc: false });
    let timeZones: any = [];
    timeZonesWithUtc.forEach(zone => {
      let selectLabel = zone.countryName + " (" + zone.mainCities.toString() + ")";
      timeZones.push({ "label": selectLabel, "value": zone.name })

      if (zone.name.toLocaleLowerCase() === Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()) {
        setTimeZone(zone.name);
      }
    });

    timeZones = timeZones.sort((a: any, b: any) => (a.label < b.label) ? -1 : 1);

    setTimeZones(timeZones);
  }

  return {
    CreateEvent,
    CreateEventItem,
    UpdateEvent,
    DeleteEvent,
    events,
    step,
    timeZone,
    timeZones,
    setTimeZone,
    changeStep,
    LoadTimeZones,
    SetFullDayOnEvent,
    maxParticipants,
    setMaxParticipants,
    setDeadline,
    setDisplayType,
    setAutomaticReminder,
    SetGoogleEvents
  };
};