/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiEmailDtoEmailEventDto
 */
export interface WebApiEmailDtoEmailEventDto {
    /**
     * 
     * @type {Date}
     * @memberof WebApiEmailDtoEmailEventDto
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailEventDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailEventDto
     */
    event?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailEventDto
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailEventDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailEventDto
     */
    messageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailEventDto
     */
    reason?: string | null;
}

/**
 * Check if a given object implements the WebApiEmailDtoEmailEventDto interface.
 */
export function instanceOfWebApiEmailDtoEmailEventDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiEmailDtoEmailEventDtoFromJSON(json: any): WebApiEmailDtoEmailEventDto {
    return WebApiEmailDtoEmailEventDtoFromJSONTyped(json, false);
}

export function WebApiEmailDtoEmailEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiEmailDtoEmailEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'event': !exists(json, 'event') ? undefined : json['event'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function WebApiEmailDtoEmailEventDtoToJSON(value?: WebApiEmailDtoEmailEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'email': value.email,
        'event': value.event,
        'from': value.from,
        'subject': value.subject,
        'messageId': value.messageId,
        'reason': value.reason,
    };
}

