/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesEmailNotificationPreference } from './WebApiModelsEnumerationTypesEmailNotificationPreference';
import {
    WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSON,
    WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSONTyped,
    WebApiModelsEnumerationTypesEmailNotificationPreferenceToJSON,
} from './WebApiModelsEnumerationTypesEmailNotificationPreference';
import type { WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatus } from './WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatus';
import {
    WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatusFromJSON,
    WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatusFromJSONTyped,
    WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatusToJSON,
} from './WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatus';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto
 */
export interface WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto {
    /**
     * 
     * @type {WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatus}
     * @memberof WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto
     */
    status?: WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatus;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto
     */
    isAllMembers?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto
     */
    groupsIds?: Array<number> | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesEmailNotificationPreference}
     * @memberof WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto
     */
    emailNotificationPreference?: WebApiModelsEnumerationTypesEmailNotificationPreference;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsMemberServiceQueryMemberDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsMemberServiceQueryMemberDtoFromJSON(json: any): WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto {
    return WebApiServicesDataTransferObjectsMemberServiceQueryMemberDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsMemberServiceQueryMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatusFromJSON(json['status']),
        'isAllMembers': !exists(json, 'isAllMembers') ? undefined : json['isAllMembers'],
        'groupsIds': !exists(json, 'groupsIds') ? undefined : json['groupsIds'],
        'emailNotificationPreference': !exists(json, 'emailNotificationPreference') ? undefined : WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSON(json['emailNotificationPreference']),
    };
}

export function WebApiServicesDataTransferObjectsMemberServiceQueryMemberDtoToJSON(value?: WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': WebApiServicesDataTransferObjectsMemberServiceMemberPaymentStatusToJSON(value.status),
        'isAllMembers': value.isAllMembers,
        'groupsIds': value.groupsIds,
        'emailNotificationPreference': WebApiModelsEnumerationTypesEmailNotificationPreferenceToJSON(value.emailNotificationPreference),
    };
}

