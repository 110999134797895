import { useEffect, useState } from 'react';
import { Button, Form, message } from 'antd';
import 'moment-timezone'
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import { AuthProvider } from '../../Providers/authProvider';
import { BookyInput } from '../../Components/Forms/BookyInput';
import { CompanyService } from '../../Services/CompanyService';
import { BookySelectDropDown } from '../../Components/Forms/BookySelectDropDown';

const AccountForm = () => {
    const [event, setEvent] = useState([] as any);
    const [language, setLanguage] = useState("" as string);

    const [form] = Form.useForm();
    const [user, setUser] = useState({} as AuthenticatedUser | null);

    const GetText = TextService.GetText;

    const FormCreateClick = async (values: any) => {
        await CompanyService.UpdateCompany({
            language: language,
            name: values.companyname,
            email: values.companyemail,
            paymentConfiguration: values.paymentConfiguration,
            companyTypeConfiguration: values.companyTypeConfiguration
        });
        message.success(GetText("Common_SettingsUpdated"));
    };

    const handleChange = (value: string) => {
        setLanguage(value);
    };

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        })

        CompanyService.GetCompany().then(company => {
            handleChange(company.language!);
            form.setFieldValue("companyemail", company.email!)
            form.setFieldValue("companyname", company.name!)
            form.setFieldValue("language", company.language!)
            form.setFieldValue("companyTypeConfiguration", company.companyTypeConfiguration!)
            form.setFieldValue("paymentConfiguration", company.paymentConfiguration!)
        })

    }, []);

    const languageOptions = [
        { value: 'se', label: GetText("AccountForm_Swedish") },
        { value: 'en', label: GetText("AccountForm_English") },
    ];

    const paymentOptions = [
        { value: 0, label: "Inga betalningar" },
        { value: 1, label: "Helårs betalningar" },
    ];

    const companyTypeOptions = [
        { value: 0, label: "Medlemshantering" },
        { value: 1, label: "Kundhantering" },
    ];

    return (
        <Form
            colon={false}
            form={form}
            labelCol={{
                span: 3,
            }}
            onFinish={FormCreateClick}
            wrapperCol={{
                span: 6,
            }}
            layout="vertical"
        >
            <BookyInput label={GetText("AccountForm_CompanyName")} name="companyname" req></BookyInput>
            <BookyInput label={GetText("AccountForm_CompanyEmail")} req validateEmailIfFilledIn={true} name="companyemail" ></BookyInput>
            <BookySelectDropDown label="Språk" handleChange={handleChange} name="language" options={languageOptions} />
            <BookySelectDropDown label="Betalningar" handleChange={(opt: any) => form.setFieldValue("paymentConfiguration", opt)} name="paymentConfiguration" options={paymentOptions} />
            <BookySelectDropDown label="Typ av organisation" handleChange={(opt: any) => form.setFieldValue("companyTypeConfiguration", opt)} name="companyTypeConfiguration" options={companyTypeOptions} />

            <Form.Item label={" "}>
                <Button data-testid="submit-button" style={{ float: 'left' }} size="middle" type="primary" htmlType="submit">
                    {GetText("AccountForm_LanguageSave")}
                </Button>
            </Form.Item>
        </Form>)

};

export default AccountForm;