import Title from 'antd/lib/typography/Title';
import Icon, { CalendarOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, DatePickerProps, Divider, Form, InputNumber, Radio, Row, Select, Space, Switch } from 'antd';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useCreateEvent } from '../../Hooks/useCreateEvent';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import moment from 'moment'
import 'moment-timezone'
import Paragraph from 'antd/lib/typography/Paragraph';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { GoogleService } from '../../Services/GoogleService';
import { CalenderSource } from '../../Types/CalendarEvent';
import { GoogleIcon } from '../../Components/Buttons/GoogleButton';
import BookyBox from '../../Components/Layout/BookyBox';
import { GooglePlaceAutoComplete } from '../../Components/AutoComplete/GooglePlaceAutocomplete';
import { BookyInput, InputType } from '../../Components/Forms/BookyInput';
import { BookyCustomInput } from '../../Components/Forms/BookyCustomInput';
import { BookyTitleWithIcon } from '../../Components/Forms/TitleWithIcon';
import { GoogleTokenProvider, GoogleAuthenticationScope } from '../../Providers/GoogleTokenProvider';
import { AuthProvider } from '../../Providers/authProvider';
import PageWide from '../../Components/Page/PageWide';
import { BookyWeekCalendar } from '../../Components/Calendar/WeekCalendar';
import { BookyEventList } from '../../Components/Calendar/BookyEventList';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { Dayjs } from 'dayjs';

const Create = () => {

  const { events, step, changeStep, CreateEventItem, UpdateEvent, DeleteEvent, CreateEvent, timeZone, setTimeZone, LoadTimeZones, timeZones, SetFullDayOnEvent, setMaxParticipants, maxParticipants, setDeadline, setDisplayType, setAutomaticReminder, SetGoogleEvents } = useCreateEvent();
  const navigation = useNavigate();
  const [startDate, setStartDate] = useState("" as string);
  const [endDate, setEndDate] = useState("" as string);
  const [googleToken, setGoogleToken] = useState("" as string);
  const [showGoogleCalender, setShowGoogleCalender] = useState(false as boolean);
  const [user, setUser] = useState([] as any);
  const [form] = Form.useForm()
  const [show24hrsFormat, setShow24hrsFormat] = useState(false as boolean);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then((user) => {
      setUser(user);
    });
    LoadTimeZones();

    if (getCurrentLanguage() === "se") {
      setShow24hrsFormat(true);
    }
  }, [])

  const onDeleteEvent = (id: any) => {
    DeleteEvent(id);
  }

  const OnCancelClick = async () => {
    navigation("/" + getCurrentLanguage() + "/myevents/");
  }

  const FormCreateClick = async (formValues: any) => {
    const newlyCreatedEvent = await CreateEvent(formValues);
    navigation("/" + getCurrentLanguage() + "/myevents/created/" + newlyCreatedEvent.uniqueId);
  }

  const getEventBookyEvents = () => {
    return events
      .filter(item => item.source === CalenderSource.EventBooky);
  }

  const OnStepChange = (event: any) => {
    changeStep(event);
  }

  const OnMaxParticipantsChange = (event: any) => {
    setMaxParticipants(event);
  }

  const onDisplayTypeChange = (event: any) => {
    setDisplayType(event.target.checked ? 1 : 0);
  }

  const onTimeZoneChange = (value: string) => {
    setTimeZone(value);
  };

  const onNavigate = (date: any) => {
    setStartDate(moment(date).startOf('week').toISOString());
    setEndDate(moment(date).endOf('week').toISOString());

    if (googleToken != null) {
      LoadGoogleEvents(googleToken, moment(date).startOf('week').toISOString(), moment(date).endOf('week').toISOString());
    }

  }

  const AuthenticateGoogle = async () => {
    if (googleToken) {
      if (!startDate) {
        LoadGoogleEvents(googleToken, moment().startOf('week').toISOString(), moment().endOf('week').toISOString());
      }
      else {
        LoadGoogleEvents(googleToken, startDate, endDate);
      }
    }
    else {
      GoogleTokenProvider.GetToken((response: any) => {
        setGoogleToken(response.access_token);
        if (!startDate) {
          LoadGoogleEvents(response.access_token, moment().startOf('week').toISOString(), moment().endOf('week').toISOString());
        }
        else {
          LoadGoogleEvents(response.access_token, startDate, endDate);
        }
      }, GoogleAuthenticationScope.CalendarReadOnly);
    }
  }

  const LoadGoogleEvents = async (token: string, start: string, end: string) => {
    if (!token) {
      return;
    }
    var events = await GoogleService.GetGoogleCalenderEvents(token, start, end);
    SetGoogleEvents(events.items);
    setShowGoogleCalender(true);
  }

  const RemoveGoogleEvents = () => {
    setShowGoogleCalender(false);
    SetGoogleEvents([]);
  }

  const onChange = (
    (date: Dayjs | string[] | null | undefined, dateString: string | string[]) => {
      setDeadline(dateString.toString());
    });

  const isPremium = () => {
    return user?.subscriptionLevel != SubscriptionLevel.Free
  }

  return <PageWide>
    <Form
      form={form}
      data-testid="create-form"
      name="basic"
      onFinish={FormCreateClick}
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      autoComplete="off"
    >
      <BookyBox>
        <Title>{GetText("Create_CreateEvent")}</Title>
        <Divider className="BookyTitleWithIconDivider" />
        <BookyInput label={GetText("Create_Name")} name="name" requiredMessage={GetText("Create_Name_Required")} placeholder={GetText("Create_Name_Placeholder")} />
        <BookyInput label={GetText("Create_Description")} name="eventDescription" type={InputType.multiline} placeholder={GetText("Create_Description_Placeholder")} />
        <BookyCustomInput label={GetText("Create_Location")} name="eventLocation">
          <GooglePlaceAutoComplete onPlaceSelected={(place: any) => { form.setFieldValue("eventLocation", place) }} />
        </BookyCustomInput>
      </BookyBox>

      <BookyBox>
        <BookyTitleWithIcon text={GetText("Create_Times")}>
          <CalendarOutlined />
        </BookyTitleWithIcon>

        <Paragraph style={{ justifyContent: 'left', width: "100%", marginBottom: "10px" }}>Välj först hur långt eventet ska pågå (antal minuter eller heldagsaktivitet). <br />Klicka sedan på kalendern nedan för att lägga till en eller flera datum/tider.</Paragraph>

        <div style={{ justifyContent: 'left', width: "100%", marginBottom: "10px" }}><b>Kalenderinställningar</b></div>
        <Row>
          <Col>
            <BookyCustomInput>
              <Radio.Group value={step} onChange={(e) => { OnStepChange(e.target.value) }} defaultValue="a" buttonStyle="solid">
                <Radio.Button value={30}>30 min</Radio.Button>
                <Radio.Button value={60}>60 min</Radio.Button>
                <Radio.Button value={80}>{GetText("MyEventsEdit_CustomTime")}</Radio.Button>
                <Radio.Button value={-2}>{GetText("MyEventsEdit_AllDay")}</Radio.Button>
              </Radio.Group>
              {(step != 15 && step != 30 && step != 60 && step != -2) ? <div style={{ width: "100%" }}>
                <InputNumber style={{ justifyContent: 'left' }} min={5} max={600} value={step} onChange={OnStepChange} /> </div> : ""}

            </BookyCustomInput>
          </Col>
        </Row>


        <Row>
          <Col xs={24} xl={6}>

          </Col>
        </Row>
        <Row>
          <Col span={20}>
            <BookyWeekCalendar startDate={startDate} onNavigate={onNavigate} DeleteEvent={DeleteEvent} events={events} CreateEventItem={CreateEventItem} UpdateEvent={UpdateEvent} show24hrsFormat={show24hrsFormat} />
          </Col>
          <Col span={4}>
            <div>
              <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <b>{GetText("Create_DateTimeTitle")} ({getEventBookyEvents().length})</b>
              </div>
              <BookyEventList events={getEventBookyEvents()} onDeleteEvent={onDeleteEvent} SetFullDayOnEvent={SetFullDayOnEvent} show24hrsFormat={show24hrsFormat} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <BookyCustomInput>
              <Select
                showSearch
                style={{ marginTop: "5px" }}
                placeholder={GetText("Create_Timezone_Placeholder")}
                optionFilterProp="children"
                value={timeZone}
                onChange={onTimeZoneChange}
                filterOption={(input, option) =>
                  (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                }
                options={timeZones}
              />
            </BookyCustomInput>
          </Col>
        </Row>
      </BookyBox>

      <BookyBox alignLeft={true}>
        <BookyTitleWithIcon text={GetText("Create_Settings")}>
          <SettingOutlined />
        </BookyTitleWithIcon>

        <BookyCustomInput label={GetText("Create_Answers")} name="answerOptions" helpText={GetText("Create_Answers_Placeholder")}>
          <Radio.Group defaultValue={0} onChange={(e) => { form.setFieldValue("answerOptions", e.target.value) }}>
            <Space direction="vertical" onClick={() => { }}>
              <Radio value={0}>{GetText("Create_YesNoMaybe")}</Radio>
              <Radio value={1}>{GetText("Create_YesNo")}</Radio>
              <Radio value={2}>{GetText("Create_Yes")}</Radio>
            </Space>
          </Radio.Group>
        </BookyCustomInput>

        <BookyCustomInput label={GetText("Create_Deadline")} helpText={GetText("Create_Deadline_Placeholder")}>

          {isPremium() ?
            <DatePicker placeholder={GetText("Create_Deadline_Set")} style={{ justifyContent: 'left' }} format={"YYYY-MM-DD HH:mm"} showTime={{ format: 'HH:mm' }} onChange={onChange} /> :
            <span className='pro-link' style={{ justifyContent: 'left', display: 'flex' }}><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a>to set deadline.</span>
          }
        </BookyCustomInput>

        <BookyCustomInput label={GetText("Create_GuestsMax")}>
          {!isPremium() ?
            <span>
              <InputNumber min={1} max={25} defaultValue={maxParticipants} onChange={(OnMaxParticipantsChange)} />
              <Paragraph style={{ fontSize: "80%" }}>{GetText("Create_Guests_Premium_RestrictNumberOfGuests")}<br></br> <a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a> {GetText("Create_Guests_Premium_ToAllowMoreGuests")}</Paragraph>
            </span>
            :
            <span>
              <InputNumber min={1} max={999} defaultValue={maxParticipants} onChange={OnMaxParticipantsChange} />
              <Paragraph style={{ fontSize: "80%" }}>{GetText("Create_Guests_Premium_RestrictNumberOfGuests")}</Paragraph>
            </span>
          }
        </BookyCustomInput>

        <BookyCustomInput label={GetText("Create_Visibility")} helpText={GetText("Create_Visibility_Placeholder")}>
          <Checkbox onChange={onDisplayTypeChange}>{GetText("Create_Visibility_HideParticipants")}</Checkbox>
        </BookyCustomInput>

        <BookyCustomInput label={GetText("Create_AutomaticReminder")} helpText={GetText("Create_AutomaticReminder_Placeholder")}>
          {isPremium() ?
            <Checkbox onChange={(e) => { setAutomaticReminder(e.target.checked) }}>{GetText("Create_AutomaticReminder_Send")}</Checkbox> :
            <span className='pro-link' style={{ justifyContent: 'left', display: 'flex' }}><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a> {GetText("Create_AutomaticReminder_Premium_ToSendAutomaticReminder")}</span>
          }
        </BookyCustomInput>

        {!isPremium() ?
          <BookyCustomInput label="Ads" helpText={GetText("Create_NoAds")}>
            <span className='pro-link' style={{ justifyContent: 'left', display: 'flex' }}><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a> {GetText("Create_AutomaticReminder_Premium_ToRemoveAds")}</span>
          </BookyCustomInput> : ""}
        {user?.token != null ?
          <BookyCustomInput>
            <Space>
              <Button data-testid="submit-button" size="middle" type="default" onClick={OnCancelClick}>
                {GetText("Common_Cancel")}
              </Button>

              <Button data-testid="submit-button" size="middle" type="primary" htmlType="submit">
                {GetText("Create_CreateEvent")}
              </Button>
            </Space></BookyCustomInput> : <Space>{GetText("Create_LoginToCreate")} <a href='/CreateUser'>{GetText("Create_CreateNewAccount")}</a></Space>}
      </BookyBox>
    </Form>
  </PageWide >
};

export default Create;