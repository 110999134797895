export const validateEmail = (email: string) => {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result: boolean = expression.test(email); // true

    return result;
}

export const isNumeric = (value: string): boolean => {
    return !new RegExp(/[^\d]/g).test(value.trim());
}

export const validatePhoneNumber = (phoneNumber: string): boolean => {
    var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return regex.test(phoneNumber) ? true : false;
}