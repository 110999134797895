import { useState } from "react";
import { AutoComplete, Button, Input, List } from "antd";
import React from "react";
import { TextService } from "../../Services/TextService";
import { WebApiMemberDtoMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto } from "../../Repository/eventbookyapi";

export const MemberAutocomplete = (props: any) => {

    const [value, setValue] = useState("");
    const [allMembers, setAllMembers] = useState([] as WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto[]);
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const GetText = TextService.GetText;

    React.useEffect(() => {
        setAllMembers(props.members);
    }, [props]);

    const onSearch = async (searchText: string) => {
        setValue(searchText);

        var result = getSuggestions(searchText);
        setOptions(
            !searchText ? [] : result,
        );
    };

    const getSuggestions = (searchValue: any) => {
        var inviteAutoCompleteSuggestion: any[] = [];

        const filteredMembers = allMembers.filter((itemData: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto) => {
            return itemData.email != "" && (itemData.firstName?.toLocaleLowerCase().startsWith(searchValue) ||
                itemData.lastName?.toLocaleLowerCase().startsWith(searchValue) ||
                (itemData.firstName + " " + itemData.lastName).toLocaleLowerCase().startsWith(searchValue) ||
                itemData.uniqueId?.startsWith(searchValue) ||
                itemData.email?.toLocaleLowerCase().startsWith(searchValue));
        })

        filteredMembers.forEach((member: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto) => {
            inviteAutoCompleteSuggestion.push({
                "label": member.firstName + " " + member.lastName + " <" + member.email + ">", "value": member.email
            });
        });

        return inviteAutoCompleteSuggestion;
    }

    const onMemberSelect = (email: string) => {
        props.onAutoCompleteSelect(allMembers.find((e: WebApiMemberDtoMemberDto) => e.email === email));
    }


    return (
        <div style={props.style}>
            <AutoComplete
                options={options}
                style={{ width: 400 }}
                onSelect={onMemberSelect}
                onSearch={onSearch}
                placeholder={GetText("MemberAutocomplete__Placeholder")}
                value={value}>
            </AutoComplete>
        </div>
    );
};