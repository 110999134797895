/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDto } from './WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDto';
import {
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDtoToJSON,
} from './WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDto';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDto>}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto
     */
    members?: Array<WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDto> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceCreateGroupDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceCreateGroupDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto {
    return WebApiServicesDataTransferObjectsGroupServiceCreateGroupDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceCreateGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'members': !exists(json, 'members') ? undefined : (json['members'] === null ? null : (json['members'] as Array<any>).map(WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDtoFromJSON)),
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceCreateGroupDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'members': value.members === undefined ? undefined : (value.members === null ? null : (value.members as Array<any>).map(WebApiServicesDataTransferObjectsGroupServiceCreateGroupMemberDtoToJSON)),
    };
}

