/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesMemberStatus } from './WebApiModelsEnumerationTypesMemberStatus';
import {
    WebApiModelsEnumerationTypesMemberStatusFromJSON,
    WebApiModelsEnumerationTypesMemberStatusFromJSONTyped,
    WebApiModelsEnumerationTypesMemberStatusToJSON,
} from './WebApiModelsEnumerationTypesMemberStatus';

/**
 * 
 * @export
 * @interface WebApiMemberDtoAddMemberObjectDto
 */
export interface WebApiMemberDtoAddMemberObjectDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    email2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    postalArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    uniqueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    extra1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    extra2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    extra3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    groups?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesMemberStatus}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    status?: WebApiModelsEnumerationTypesMemberStatus;
    /**
     * 
     * @type {number}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    paymentAmountYearMinusTwo?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    paymentAmountYearMinusOne?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    paymentAmountCurrentYear?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiMemberDtoAddMemberObjectDto
     */
    paymentAmountNextYear?: number;
}

/**
 * Check if a given object implements the WebApiMemberDtoAddMemberObjectDto interface.
 */
export function instanceOfWebApiMemberDtoAddMemberObjectDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoAddMemberObjectDtoFromJSON(json: any): WebApiMemberDtoAddMemberObjectDto {
    return WebApiMemberDtoAddMemberObjectDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoAddMemberObjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoAddMemberObjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'postalArea': !exists(json, 'postalArea') ? undefined : json['postalArea'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
        'extra1': !exists(json, 'extra1') ? undefined : json['extra1'],
        'extra2': !exists(json, 'extra2') ? undefined : json['extra2'],
        'extra3': !exists(json, 'extra3') ? undefined : json['extra3'],
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
        'status': !exists(json, 'status') ? undefined : WebApiModelsEnumerationTypesMemberStatusFromJSON(json['status']),
        'paymentAmountYearMinusTwo': !exists(json, 'paymentAmountYearMinusTwo') ? undefined : json['paymentAmountYearMinusTwo'],
        'paymentAmountYearMinusOne': !exists(json, 'paymentAmountYearMinusOne') ? undefined : json['paymentAmountYearMinusOne'],
        'paymentAmountCurrentYear': !exists(json, 'paymentAmountCurrentYear') ? undefined : json['paymentAmountCurrentYear'],
        'paymentAmountNextYear': !exists(json, 'paymentAmountNextYear') ? undefined : json['paymentAmountNextYear'],
    };
}

export function WebApiMemberDtoAddMemberObjectDtoToJSON(value?: WebApiMemberDtoAddMemberObjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'email2': value.email2,
        'address': value.address,
        'postalCode': value.postalCode,
        'postalArea': value.postalArea,
        'title': value.title,
        'country': value.country,
        'phoneNumber': value.phoneNumber,
        'company': value.company,
        'uniqueId': value.uniqueId,
        'extra1': value.extra1,
        'extra2': value.extra2,
        'extra3': value.extra3,
        'groups': value.groups,
        'status': WebApiModelsEnumerationTypesMemberStatusToJSON(value.status),
        'paymentAmountYearMinusTwo': value.paymentAmountYearMinusTwo,
        'paymentAmountYearMinusOne': value.paymentAmountYearMinusOne,
        'paymentAmountCurrentYear': value.paymentAmountCurrentYear,
        'paymentAmountNextYear': value.paymentAmountNextYear,
    };
}

